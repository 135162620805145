(function () {
    'use strict';

    angular
        .module('dspApp')
        .service('AirtableServices', AirtableServices);

    AirtableServices.$inject=['$http', 'ENV'];
    function AirtableServices($http, ENV) {

        var services = {
            get_parking_all:get_parking_all,
            get_parking_by_id:get_parking_by_id,
            get_popular_all:get_popular_all,
            get_explore_main:get_explore_main,
            get_explore:get_explore,
            get_about_us:get_about_us,
            get_banner:get_banner,
            get_epark:get_epark,
            post_sign_in:post_sign_in
        };

        var auth = 'Bearer ' + ENV.API_KEY;
        var api_url = ENV.API_URL;
        var epark_url = ENV.FE_URL + 'epark.json';

        //OLD
        //https://airtable.com/appVZIOmFf2hWRXJr/tblhYn1Va7Fzz6t9h/viw1SmZCvmffkdivr?blocks=hide

        // var table_lots = 'lots';
        // var table_popular = 'popular';
        // var table_sign_in = 'SIGN-IN-FORM';
        // var table_explore_main = 'EXPLORE-MAIN';
        // var table_explore = 'EXPLORE';
        // var table_about_us = 'ABOUT-US';
        // var table_banner = 'BANNER';

        //NEW
        //https://airtable.com/appRgZmEFmxFNnreC/tbl6xTHx0c2DIEuxZ/viwNzZwarNoruL3iH?blocks=hide
        var table_lots = 'GARAGE-INFO';
        var table_popular = 'POPULAR';
        var table_sign_in = 'SIGN-IN-FORM';
        var table_explore_main = 'EXPLORE-MAIN';
        var table_explore = 'EXPLORE';
        var table_about_us = 'ABOUT-US';
        var table_banner = 'BANNER';

        if(typeof ENV.STAGING !== "undefined"){
            if(ENV.STAGING){
                table_lots = 'GARAGE%20INFO';
                table_popular = 'POPULAR';
            }
        }

        return services;

        function get_parking_all(){

            return $http.get(api_url + table_lots +"?filterByFormula=status%3D%22active%22",{
                headers: {
                    'Authorization': auth
                }
            });

        }
        function get_parking_by_id(id){

            var url = api_url + "lots/" + id;

            return $http.get(url,{
                headers: {
                    'Authorization': auth
                }
            });

        }
        function get_popular_all(){

            return $http.get(api_url + table_popular + "?filterByFormula=status%3D%22active%22",{
                headers: {
                    'Authorization': auth
                }
            });

        }
        function get_explore_main(){

            return $http.get(api_url + table_explore_main + "?filterByFormula=status%3D%22active%22",{
                headers: {
                    'Authorization': auth
                }
            });

        }
        function get_explore(){

            return $http.get(api_url + table_explore + "?filterByFormula=status%3D%22active%22",{
                headers: {
                    'Authorization': auth
                }
            });

        }
        function get_about_us(){

            return $http.get(api_url + table_about_us + "?filterByFormula=status%3D%22active%22",{
                headers: {
                    'Authorization': auth
                }
            });

        }
        function get_banner(filter){

            filter = typeof filter === 'undefined' ? '' : filter;

            return $http.get(api_url + table_banner + filter,{
                headers: {
                    'Authorization': auth
                }
            });

        }
        function get_epark(){
            return $http.get(epark_url, {});
        }
        function post_sign_in(data){

            return $http.post(api_url + table_sign_in, data, {
                headers: {
                    'Authorization': auth
                }
            });

        }

    }
})();