(function () {
    'use strict';

    angular
        .module('dspApp')
        .controller('HomeBakCtrl', HomeBakCtrl);

    HomeBakCtrl.$inject = ['AirtableServices', 'GeneralServices', '$state', '$scope', '$location', '$rootScope', 'Analytics', '$interval', '$timeout', 'ENV', '$window'];

    function HomeBakCtrl(AirtableServices, GeneralServices, $state, $scope, $location, $rootScope, Analytics, $interval, $timeout, ENV, $window) {
        var vm = this;

        vm.googleMapsUrl = ENV.GMAPS_URL;

        var getEParkingInterval;

        function stopGetEParkingInterval() {
            if (angular.isDefined(getEParkingInterval)) {
                $interval.cancel(getEParkingInterval);
                getEParkingInterval = undefined;
            }
        }

        var hide_sidebar = angular.element(document.querySelector('.sidebar'));
        var hide_footer = angular.element(document.querySelector('.footer-parent'));

        var path = $location.path();

        $('body').removeClass('shuttle-overflow');

        if (_.includes(path, 'shuttle')) {
            $('body').addClass('shuttle-overflow');
            vm.shuttle = true;
        } else {
            vm.shuttle = false;
        }

        var temp_check = _.includes(path, 'lots');

        if ((temp_check && $window.innerWidth < 768) || (vm.shuttle && $window.innerWidth < 768)) {
            hide_sidebar.addClass('hidden');
            hide_footer.addClass('hidden');
        } else {
            hide_sidebar.removeClass('hidden');
            hide_footer.removeClass('hidden');
        }

        function checkResize() {
            $timeout(function () {
                if ($window.innerWidth < 768) {
                    vm.small_res = true;
                    vm.old_h = 0;
                    vm.cb_style = {'top': '0px'};
                } else {
                    vm.small_res = false;
                    var cb = angular.element(document.querySelector('.covid-banner.cb-main'));
                    var h = cb[0].offsetHeight + 'px';
                    vm.old_h = cb[0].offsetHeight;
                    vm.cb_style = {'top': h};
                }
            });
        }

        vm.small_res = $window.innerWidth < 768;
        vm.old_h = 0;
        checkResize();

        angular.element($window).bind('resize', function () {
            var flag = false;
            if(vm.small_res && ($window.innerWidth >= 768)){
                flag = true;
            }else if(!vm.small_res){
                var cb = angular.element(document.querySelector('.covid-banner.cb-main'));
                var h = cb[0].offsetHeight;
                if(vm.old_h !== h){
                    flag = true;
                }
            }
            if(flag){
                checkResize();
            }
        });

        Analytics.trackPage($location.$$path);

        $scope.$on('$locationChangeSuccess', function (event, newUrl, oldUrl, newState) {

            Analytics.trackPage($location.$$path);
            var path = $location.path();

            if (_.includes(path, 'shuttle')) {
                $('body').addClass('shuttle-overflow');
                vm.shuttle = true;
            } else {
                $('body').removeClass('shuttle-overflow');
                vm.shuttle = false;
            }

            var temp_check2 = _.includes(path,'lots');
            if((temp_check2 && $window.innerWidth < 768) || (vm.shuttle && $window.innerWidth < 768)){
                hide_sidebar.addClass('hidden');
                hide_footer.addClass('hidden');
            }else{
                hide_sidebar.removeClass('hidden');
                hide_footer.removeClass('hidden');
            }

            checkResize();
            checkUrl();

        });

        vm.mobile_home = true;
        vm.show_mobile_map = false;
        vm.lot_list_show = false;
        if ($window.innerWidth > 767) {
            vm.mobile_home = false;
        }

        vm.show_search = true;

        var get_distance = GeneralServices.get_object_cookies('get_distance');

        if (!get_distance) {
            vm.view_on_map_show = false;
            vm.lot_list_show = false;
            vm.hours_lots_show = false;
            vm.neighborhood_show = false;
            vm.near_show = false;
            vm.popular_show = false;
            vm.popular_parking_show = false;
        }

        vm.first_load = true;
        vm.first_load_popular = true;

        vm.data_lat_lng = [];
        vm.data_lat_lng_popular = [];

        vm.flag_get_current_position = false;
        vm.flag_get_current_position_popular = false;

        vm.current_popular = {};

        $scope.$on('showDistance', function (event, data) {
            ShowDistance(data);
        });

        function ShowDistance(data) {
            _.forEach(vm.list_lot, function (v1, k1) {
                _.forEach(data.value, function (v2, k2) {
                    if (v1.id === v2.id) {
                        v1.fields.distance = v2.distance;
                        v1.fields.origin = data.origin;
                    }
                });
            });

            vm.hours_lots_show = false;
            vm.neighborhood_show = false;
            vm.view_on_map_show = false;
            $("body").find(".view-arrow").removeClass("show");

            var temp_lot_list = _.sortBy(vm.list_lot, [function (o) {
                return o.fields.distance;
            }]);
            vm.list_lot = temp_lot_list;
            vm.lot_list_show = true;
        }

        function ShowDistanceMobile(data) {
            _.forEach(vm.list_lot, function (v1, k1) {
                _.forEach(data.value, function (v2, k2) {
                    if (v1.id === v2.id) {
                        v1.fields.distance = v2.distance;
                        v1.fields.origin = data.origin;
                    }
                });
            });

            vm.hours_lots_show = false;
            vm.neighborhood_show = false;
            vm.view_on_map_show = false;
            $("body").find(".view-arrow").removeClass("show");

            var temp_lot_list = _.sortBy(vm.list_lot, [function (o) {
                return o.fields.distance;
            }]);
            vm.list_lot = temp_lot_list;
        }

        GetParkingAll();

        function GetParkingAll() {
            AirtableServices.get_parking_all().then(function (response) {
                var list = response.data.records;

                vm.list = list.slice();
                vm.list_lot = list.slice();
                vm.list_near = list.slice();
                vm.list_popular_parking_cheap = list.slice();
                vm.list_popular_parking_nearby = list.slice();

                if (get_distance) {
                    ShowDistanceMobile(get_distance);
                }

                _.forEach(vm.list_near, function (v, k) {

                    var lat = parseFloat(v.fields.lat);
                    var lng = parseFloat(v.fields.lng);

                    vm.data_lat_lng.push({lat: lat, lng: lng, id: v.id});
                });

                // GetEPark();

                GetPopularAll();

                navigator.geolocation.getCurrentPosition(successGetLocation, errorGetLocation, options);

                // $timeout(function () {
                //     getEParkingInterval = $interval(function(){
                //         $timeout(function () {
                //             GetEPark();
                //         }, 1000);
                //     }, 60000);
                // }, 60000);

            }, function (error) {
                console.log(error)
            }).finally(function () {

            });
        }

        function GetPopularAll() {
            AirtableServices.get_popular_all().then(function (response) {
                vm.list_popular = response.data.records;

                _.forEach(vm.list_popular, function (v, k) {

                    var lat = parseFloat(v.fields.lat);
                    var lng = parseFloat(v.fields.lng);

                    vm.data_lat_lng_popular.push({lat: lat, lng: lng, id: v.id});
                });

                navigator.geolocation.getCurrentPosition(successGetLocationPopular, errorGetLocationPopular, options);

                checkUrl();

            }, function (error) {
                console.log(error)
            }).finally(function () {

            });
        }

        function GetPopularParkingDistance(popular) {
            var pop_lat = parseFloat(popular.fields.lat);
            var pop_lng = parseFloat(popular.fields.lng);

            var from_latlng = {lat: pop_lat, lng: pop_lng};

            var popular_parking = [];

            _.forEach(vm.list, function (v, k) {

                var lat = parseFloat(v.fields.lat);
                var lng = parseFloat(v.fields.lng);

                popular_parking.push({lat: lat, lng: lng, id: v.id});
            });
            calculateDistancePopular(from_latlng, popular_parking);
        }

        function GetEPark() {

            AirtableServices.get_epark().then(function (response2) {

                // $rootScope.$emit('updateEPark', response2);

                _.forEach(vm.list, function (v, k) {

                    if (v.fields.epark === 'yes') {

                        _.forEach(response2.data, function (v2, k2) {

                            if (v2.Id == v.fields.GarageID) {

                                if (v2.OutOfService === true) {
                                    v.fields.out_of_service = true;
                                    v.fields.percentage = '0%';
                                    return false;
                                } else {
                                    v.fields.vacant = v2.VacantSpaces;
                                    v.fields.out_of_service = false;

                                    if (v.fields.total_space < v2.VacantSpaces) {

                                        v.fields.total_space = 0;

                                    } else {

                                        if (v2.VacantSpaces < 0) {
                                            v.fields.percentage = '100%';
                                        } else {
                                            var num = Math.floor(((v.fields.total_space - v2.VacantSpaces) / v.fields.total_space) * 100);
                                            v.fields.percentage = String(num) + '%';
                                        }

                                    }

                                    return false;
                                }
                            }
                        });
                    }
                });

            }, function (error) {
                console.log(error)
            }).finally(function () {

            });
        }

        var options = {
            enableHighAccuracy: true,
            timeout: 5000,
            maximumAge: 0
        };

        function successGetLocation(pos) {
            vm.flag_get_current_position = true;
            var lat = parseFloat(pos.coords.latitude);
            var lng = parseFloat(pos.coords.longitude);

            var from_latlng = {lat: lat, lng: lng};

            calculateDistance('near', from_latlng, vm.data_lat_lng);
        }

        function successGetLocationPopular(pos) {
            vm.flag_get_current_position_popular = true;
            var lat = parseFloat(pos.coords.latitude);
            var lng = parseFloat(pos.coords.longitude);

            var from_latlng = {lat: lat, lng: lng};

            calculateDistance('popular', from_latlng, vm.data_lat_lng_popular);
        }

        function errorGetLocation(err) {

            var message = '';
            if (vm.first_load) {
                vm.first_load = false;
            } else {
                vm.first_load = false;

                switch (err.code) {
                    case 1:
                    case 99:
                        message = "Your browser has denied permission to share your location. Please change your browser settings to allow this page to query your location and try again, or follow one of the other links to find parking in a different way.";
                        break;
                    case 2:
                    case 3:
                        message = "Sorry, I can't find your location at this time. Please try again later.";
                        break;
                    default:
                        message = "Sorry, there was an error finding your location. Please try again later.";
                }

                $('.overlay .message p').html(message);
                $('.overlay .message').show();
                $('.overlay').fadeIn();
            }
        }

        function errorGetLocationPopular(err) {

            var message = '';
            if (vm.first_load_popular) {
                vm.first_load_popular = false;
            } else {
                vm.first_load_popular = false;

                switch (err.code) {
                    case 1:
                    case 99:
                        message = "Your browser has denied permission to share your location. Please change your browser settings to allow this page to query your location and try again, or follow one of the other links to find parking in a different way.";
                        break;
                    case 2:
                    case 3:
                        message = "Sorry, I can't find your location at this time. Please try again later.";
                        break;
                    default:
                        message = "Sorry, there was an error finding your location. Please try again later.";
                }

                $('.overlay .message p').html(message);
                $('.overlay .message').show();
                $('.overlay').fadeIn();
            }
        }

        vm.reload_page = function () {
            location.reload();
        };

        function calculateDistance(type, objFrom, arrTo) {

            vm.view_on_map_false();

            if (type === 'near') {
                _.forEach(vm.list_near, function (v1, k1) {
                    _.forEach(arrTo, function (v2, k2) {
                        if (v1.id === v2.id) {
                            v1.fields.distance = GeneralServices.calculate(objFrom, v2);
                            v1.fields.origin = objFrom;
                            return false;
                        }
                    });
                });
            } else if (type === 'popular') {
                _.forEach(vm.list_popular, function (v1, k1) {
                    _.forEach(arrTo, function (v2, k2) {
                        if (v1.id === v2.id) {
                            v1.fields.distance = GeneralServices.calculate(objFrom, v2);
                            v1.fields.origin = objFrom;
                            return false;
                        }
                    });
                });
            }
        }

        function calculateDistancePopular(objFrom, arrTo) {
            vm.list_popular_parking_cheap = vm.list.slice();

            _.forEach(vm.list_popular_parking_cheap, function (v1, k1) {
                _.forEach(arrTo, function (v2, k2) {
                    if (v1.id === v2.id) {
                        v1.fields.distance = GeneralServices.calculate(objFrom, v2);
                        v1.fields.origin = objFrom;
                        return false;
                    }
                });
            });

            var list = vm.list_popular_parking_cheap.slice();

            vm.list_popular_parking_cheap = _.filter(list, function (o) {
                return o.fields.icon === 'three' && o.fields.distance <= 0.5;
            });

            vm.list_popular_parking_nearby = _.filter(list, function (o) {
                return o.fields.icon !== 'three' && o.fields.distance <= 0.5;
            });
        }

        vm.sortKey = "alpha";
        vm.sortFilterKey = "item.fields.title";
        vm.sort = function (type) {
            if (type === 'alpha') {
                vm.sortKey = "alpha";
                vm.sortFilterKey = "fields.title";
            } else {
                if (vm.flag_get_current_position_popular) {
                    vm.sortKey = "distance";
                    vm.sortFilterKey = "fields.distance";
                } else {
                    errorGetLocationPopular({code: 99})
                }
            }
        };

        vm.ScrollTo = function () {
            $('html, body').animate({
                scrollTop: $('#shuttle-legend').offset().top
            }, 1000);
        };

        vm.goBack = function () {
            vm.view_on_map_false();
            $state.go('home', {}, {});
        };
        vm.goBackFromShuttle = function () {
            vm.view_on_map_false();
            $state.go('home', {}, {reload: true});
        };

        vm.view_by_near = function () {
            vm.view_on_map_false();

            if (vm.flag_get_current_position) {
                vm.near_show = true;
                $state.go('home.near', {}, {});

            } else {
                errorGetLocation({code: 99})
            }
        };

        vm.view_by_popular = function () {
            vm.view_on_map_false();

            vm.popular_show = true;
            $state.go('home.destinations', {}, {});
            vm.sort('alpha');

        };

        vm.view_on_map = function () {
            if ($window.innerWidth < 768) {
                $rootScope.$emit('viewOnMap', true);
                vm.show_mobile_map = true;
                $state.go('home.map', {}, {reload: true});
            } else {
                var temp = _.includes($location.path(), 'lots');
                if (temp) {
                    $state.go('home.map', {}, {reload: true});
                } else {
                    if (vm.view_on_map_show === false) {
                        vm.view_on_map_show = true;
                        $("body").find(".view-arrow").addClass("show");
                        $rootScope.$emit('viewOnMap', true);
                    }
                }
            }
        };

        $scope.$on('viewOnMapFeedback', function (event) {
            $state.go('home.map', {}, {});
        });

        $scope.$on('make_false', function (event) {
            vm.view_on_map_false();
        });

        vm.view_on_map_false = function () {
            vm.view_on_map_show = false;
            vm.lot_list_show = false;
            $("body").find(".view-arrow").removeClass("show");
            $rootScope.$emit('viewOnMap', false);
        };

        vm.view_by_neighborhood = function () {
            vm.view_on_map_false();
            vm.neighborhood_show = !vm.neighborhood_show;
        };

        vm.view_by_garage_hours = function () {
            vm.view_on_map_false();
            vm.hours_lots_show = !vm.hours_lots_show;
        };

        vm.back_list_lot_list = function () {
            GeneralServices.delete_all_cookies();
            vm.lot_list_show = false;
            $state.go('home.map', {}, {reload: true});
        };

        vm.redirect = function (gaEv, item) {
            // Analytics.trackEvent(gaEv, 'click', item.fields.slug);

            vm.view_on_map_false();

            GeneralServices.delete_all_cookies();

            if (gaEv === 'neighborhood') {
                $state.go('home.neighborhoods.parking', {id: item.fields.slug}, {});
            } else if (gaEv === 'garrage-hours') {
                $state.go('home.hours.parking', {id: item.fields.slug}, {});
            } else if (gaEv === 'near-me') {
                $state.go('home.near.parking', {id: item.fields.slug}, {});
            }
        };

        vm.redirectMarker = function (gaEv, item) {
            // Analytics.trackEvent(gaEv, 'click', item.fields.slug);
            GeneralServices.put_object_cookies('place_marker', item);
            $state.go('home.map.parking', {id: item.fields.slug}, {});
        };

        vm.redirect_neighborhood = function (gaEv, neighborhood) {
            // Analytics.trackEvent(gaEv, 'click', neighborhood);
            $rootScope.$emit('viewMapNeigborhood', neighborhood);

            $state.go('home.neighborhoods.slug', {slug: neighborhood}, {});
        };

        vm.openPopular = function (item) {
            vm.current_popular = item;
            vm.popular_show = false;
            vm.popular_parking_show = true;
            GetPopularParkingDistance(item);
            $state.go('home.destinations.slug', {slug: item.fields.slug}, {});
        };

        vm.redirectPopular = function (gaEv, item) {
            // Analytics.trackEvent(gaEv, 'click', item.fields.slug);
            vm.current_popular.fields.origin = {
                lat: parseFloat(vm.current_popular.fields.lat),
                lng: parseFloat(vm.current_popular.fields.lng)
            };
            GeneralServices.put_object_cookies('place_marker', vm.current_popular);
            $state.go('home.destinations.slug.parking', {id: item.fields.slug}, {reload: 'home.destinations.slug.parking'});
        };

        function checkUrl() {

            $('.popover').remove();

            vm.mobile_home = true;
            vm.show_mobile_map = false;
            if ($window.innerWidth > 767) {
                vm.mobile_home = false;
            }

            if ($state.current.name == 'home') {
                vm.show_search = true;
            } else {
                vm.show_search = false;
            }


            if ($state.current.name != 'home.map') {
                vm.view_on_map_false();
            } else {
                $rootScope.$emit('viewOnMap', true);

                if ($window.innerWidth > 767) {
                    vm.show_mobile_map = false;
                } else {
                    vm.show_mobile_map = true;
                }
            }

            if ($state.current.name == 'home.neighborhoods.slug') {
                if ($state.params.slug !== undefined) {
                    $rootScope.$emit('viewMapNeigborhood', $state.params.slug);
                    $state.go('home.neighborhoods.slug', {slug: $state.params.slug}, {});
                }
            } else if ($state.current.name == 'home.destinations') {
                vm.view_by_popular();
            } else if ($state.current.name == 'home.destinations.slug') {
                if ($state.params.slug !== undefined) {

                    var obj = _.filter(vm.list_popular, function (o) {
                        return o.fields.slug === $state.params.slug;
                    });

                    if (obj.length === 0) {
                        alert('Data not found.');
                    } else {
                        vm.openPopular(obj[0]);
                    }
                }
            }

            if ($state.params.id !== undefined) {

            }
        }

        vm.view_press = function () {
            var elem = angular.element(document.querySelector('.mobile-business-tools'));
            elem.removeClass('in');
        };
        vm.view_business = function () {
            var elem = angular.element(document.querySelector('.mobile-press-room'));
            elem.removeClass('in');
        };

        vm.goTo = function (state) {
            $state.go(state, {}, {reload: true});
        };

        //region popover

        $('#pop2, #pop, #pop-press, #pop-business, #pop-print-maps').popover({
            trigger: 'manual',
            placement: 'right'
        }).mousedown(function (e) {
            var txt = $(this).data('open');
            if (txt == "open") {
                $(this).popover('hide');
                $(this).data('open', 'closed')
            } else {
                $(this).popover('show');
                $(this).data('open', 'open')
            }
            e.preventDefault();
        }).click(function (e) {
            e.preventDefault();
        });


        $('body').on('click', '.popover .close', function (e) {
            e.preventDefault();
            $('#pop2, #pop, #pop-press, #pop-business, #pop-print-maps')
                .popover('hide')
                .data('open', 'closed');
        });

        //endregion popover

        vm.openNav = function () {
            document.getElementById("myNav").style.width = "100%";
        };
        vm.closeNav = function () {
            document.getElementById("myNav").style.width = "0%";
        };

    }
})();
