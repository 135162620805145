(function () {
    'use strict';

    angular
        .module('dspApp')
        .component('mapComponent', {
            templateUrl: 'app/views/map.component.html',
            controller : 'MapCtrl',
            controllerAs : 'vm',
            restrict:'E',
            scope: {
                id: '@',
            },
        })
        .component('mapHomeComponent', {
            templateUrl: 'app/views/map-home.component.html',
            controller : 'MapHomeCtrl',
            controllerAs : 'vm'
        })
        .component('searchMapComponent', {
            templateUrl: 'app/views/search-map.component.html',
            controller : 'SearchMapCtrl',
            controllerAs : 'vm'
        });

})();