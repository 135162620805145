(function () {
    'use strict';

    angular.module('dspApp', [
        'ui.router',
        'ngMap',
        'ngCookies',
        'angular-google-analytics',
        'angulartics',
        'angulartics.facebook.pixel',
        'ngAnimate',
        'hmTouchEvents',
        'slick',
        'ngSanitize',
        'btford.markdown'
    ])
        .config(config)
        .run(run);

    config.$inject = ['$urlRouterProvider', '$qProvider','AnalyticsProvider','ENV','$locationProvider'];
    function config($urlRouterProvider, $qProvider,AnalyticsProvider,ENV,$locationProvider) {
        $urlRouterProvider.when("", "/");

        $locationProvider.html5Mode(true);

        AnalyticsProvider.setAccount(ENV.GA_CODE);
        $urlRouterProvider.otherwise('/');
        $qProvider.errorOnUnhandledRejections(false);


    }
    run.$inject = ['$rootScope', '$transitions'];
    function run($rootScope, $transitions) {

      if (window.location.pathname === '/parking/shuttle' ||
        window.location.pathname === '/parking/#!/shuttle') {
        window.location.href = 'https://seattlewaterfront.org/free-seattle-waterfront-shuttle/';
      }

        //comment to remove pop up
        // $transitions.onSuccess({}, function($transition){
        //     if($transition.$from().navigable === null ||
        //         ($transition.$from().name === 'home' && $transition.$to().name === 'home') ){
        //         $rootScope.$emit('FirstLoad',true);
        //     }
        // });

    }
})();
