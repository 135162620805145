(function () {
    'use strict';

    angular
        .module('dspApp')
        .controller('EmbeddableHomeCtrl', EmbeddableHomeCtrl);

    EmbeddableHomeCtrl.$inject = ['AirtableServices', 'GeneralServices', '$state',
        '$scope', '$location', '$rootScope',
        'Analytics', '$interval', '$timeout',
        'ENV', '$window'];

    function EmbeddableHomeCtrl(AirtableServices, GeneralServices, $state,
                      $scope, $location, $rootScope,
                      Analytics, $interval, $timeout,
                      ENV, $window) {
        var vm = this;

        vm.isMobile = $window.innerWidth < 992;

        Analytics.trackPage($location.$$path);

        vm.googleMapsUrl = ENV.GMAPS_URL;

        vm.sideBanner = true;
        // $timeout(function () {
        //     vm.sideBanner = false;
        // },7000);

        vm.sortTypeDistance = false;
        vm.sortTypePrice = false;

        vm.oldSortType = '';

        vm.sortType = [
            {
                id:'distance',
                value: false
            },
            {
                id:'price',
                value: false
            },
        ];

        vm.filterType = [
            {
                id:'pioneer_square',
                value: false
            },
            {
                id:'central_waterfront',
                value: false
            },
            {
                id:'retail_core',
                value: false
            },
        ];

        vm.filterCheck = {
            price: [
                {
                    id: 'low',
                    value: false
                },
                {
                    id: 'early',
                    value: false
                },
                {
                    id: 'normal',
                    value: false
                },
            ],
            type: [
                {
                    id: 'covered',
                    value: false
                },
                {
                    id: 'uncovered',
                    value: false
                },
            ],
            access: [
                {
                    id: 'elevator',
                    value: false
                },
                {
                    id: 'one_level',
                    value: false
                },
            ],
        };

        vm.filterTypePioneer = false;
        vm.filterTypeWaterfront = false;
        vm.filterTypeRetail = false;

        vm.filter_pricing_1 = false;
        vm.filter_pricing_2 = false;
        vm.filter_type_1 = false;
        vm.filter_type_2 = false;
        vm.filter_access_1 = false;
        vm.filter_access_2 = false;

        vm.list_compare = [];

        vm.sortFilterKey = 'fields.title';

        var dataSearch = angular.copy($location.search());

        if(!_.isEmpty(dataSearch)){
            if(typeof dataSearch['myLoc'] !== "undefined"){
                $timeout(function(){
                    vm.showNearMe = false;
                },100);
            }else{
                $timeout(function(){
                    vm.showNearMe = true;
                },100);
            }
        }
        else{
            $timeout(function(){
                vm.showNearMe = true;
            },100);
        }

        vm.showMap = true;
        // vm.showFilter = true;
        // vm.showMap = vm.isMobile;

        $(".ui-container").removeClass("relative-100");

        // if(vm.showMap){
            // $("body").addClass("overflow-hidden");
            // $("body").addClass("transparent-scroll");
        // }else{
            // $("body").removeClass("overflow-hidden");
            // $("body").removeClass("transparent-scroll");
        // }

        function iPhoneVersion() {
            var iHeight = window.screen.height;
            var iWidth = window.screen.width;

            if (iWidth === 414 && iHeight === 896) {
                return "Xmax-Xr";
            }
            else if (iWidth === 375 && iHeight === 812) {
                return "X-Xs";
            }
            else if (iWidth === 320 && iHeight === 480) {
                return "4";
            }
            else if (iWidth === 375 && iHeight === 667) {
                return "6";
            }
            else if (iWidth === 414 && iHeight === 736) {
                return "6+";
            }
            else if (iWidth === 320 && iHeight === 568) {
                return "5";
            }
            else if (iHeight <= 480) {
                return "2-3";
            }
            return 'none';
        }

        function isIphone() {
            return !!navigator.userAgent.match(/iPhone/i);
        }

        vm.iphonex = false;
        vm.iphone = false;

        if(vm.isMobile){
            GeneralServices.setShowMap(true);
            vm.opener = true;
            vm.showSidebar = true;
            vm.showSearchBox = true;
            vm.showNearMe = true;

            if ( isIphone()){
               if(iPhoneVersion() === "X-Xs"){
                   vm.iphonex = true;
               }else{
                   vm.iphone = true;
               }
            }
        }

        vm.clickOpener = function(state){
            vm.opener = !state;
            vm.obj = false;
        };
        vm.clickCompare = function(state){
            vm.fullCompare = state;

            if(state){
                vm.list_compare = angular.copy(vm.list_near);
                var temp = [];
                _.forEach(vm.list_compare, function (v, k) {
                    if(v.fields.active){
                        temp.push(v);
                    }
                });
                vm.list_near = angular.copy(temp);
            }else{
                vm.list_near = angular.copy(vm.list_compare);
            }

        };
        vm.resetActive = function(){
            _.forEach(vm.list_near, function(v,k){
                v.fields.active = false;
            });
            vm.obj = false;
        };
        vm.closeStandAlone = function(){
            vm.obj = false;
        };
        vm.FindParkNearMe = function(){
            $rootScope.$emit('removeSearch');
            vm.resetActive();
            navigator.geolocation.getCurrentPosition(successGetLocation, errorGetLocation, options);
        };

        function reportWindowSize() {
            if($window.innerWidth < 992){
                // $("body").addClass("is-mobile");
                vm.isMobile = true;
            }else{
                // $("body").addClass("is-mobile");
                vm.isMobile = false;
            }
        }

        $window.onresize = reportWindowSize;

        var get_distance = GeneralServices.get_object_cookies('get_distance');

        if (!get_distance) {
            vm.view_on_map_show = false;
            vm.lot_list_show = false;
            vm.hours_lots_show = false;
            vm.neighborhood_show = false;
            vm.near_show = false;
            vm.popular_show = false;
            vm.popular_parking_show = false;
        }

        vm.first_load = true;
        vm.first_load_popular = true;

        vm.data_lat_lng = [];
        vm.data_lat_lng_popular = [];

        vm.flag_get_current_position = false;
        vm.flag_get_current_position_popular = false;

        vm.current_popular = {};

        $rootScope.$on('setOnlyShowMapOnHome', function(event) {
            vm.showMap = true;
        });
        $rootScope.$on('setShowMapOnHome', function(event) {
            GeneralServices.setShowMap(true);
            vm.showMap = true;

            // if(vm.showMap){
            //     GeneralServices.setShowMap(true);
                $rootScope.$emit('setTempSearchAgain');
            // }else{
            //     vm.showFilter = true;

                // $timeout(function(){
                //     var scrollSidebar = document.getElementById('scroll-filter');
                //     scrollSidebar.scrollTo({
                //         top: 0,
                //         behavior: 'smooth'
                //     });
                // },100);

            // }
        });
        $rootScope.$on('toggleFilter', function(event,data) {
            vm.showMap = true;
            vm.showFilter = data;
        });
        $rootScope.$on('setShowNearMe', function(event) {
            $timeout(function(){
                vm.showNearMe = true;
            },100);
        });

        $rootScope.$on('setShowNearMe', function(event) {
            $timeout(function(){
                vm.resetActive();
            },100);
        });

        vm.banner = true;
        $rootScope.$on('removeBanner', function(event) {
            $timeout(function(){
                vm.banner = false;
            },100);
        });

        $rootScope.$on('showDistance', function (event, data) {
            var lat = parseFloat(data.origin.lat);
            var lng = parseFloat(data.origin.lng);

            var from_latlng = {lat: lat, lng: lng};

            calculateDistance('near', from_latlng, vm.data_lat_lng);
        });

        GetParkingExploreMain();
        GetParkingAll();
        GetBanner();

        function GetBanner() {
            AirtableServices.get_banner().then(function (response) {
                vm.list_banner = response.data.records;

                _.forEach(vm.list_banner, function(v,k){
                   if(v.fields.placement === 'home') {
                       vm.text_banner_home = v.fields.text;
                       vm.homeBanner = v.fields.status === 'active';
                   }else if(v.fields.placement === 'map') {
                       vm.text_banner_map = v.fields.text;
                       vm.sideBanner = v.fields.status === 'active';
                   }

               });

            }, function (error) {
                console.log(error)
            }).finally(function () {

            });
        }

        function searchFirst(){

            /*sort = closest, distance
            neighbor = pioneer_square, central_waterfront, retail_core
            price = 1,2
            type = 1,2
            access = 1,2*/

            var dataSearch = angular.copy($location.search());

            if(!_.isEmpty(dataSearch)){

                vm.showMap = true;

                _.forEach(dataSearch, function(v,k){
                    if(k === 'sort'){

                        if(v === 'distance'){
                            vm.sortType[0]['value'] = true;
                            vm.sortFilterKey = "fields.distance";
                        }else{
                            vm.sortType[1]['value'] = true;
                            vm.sortFilterKey = "fields.price";
                        }

                    }else if(k === 'neighbor'){

                        var temp = v.split(',');
                        _.forEach(vm.filterType, function(v,k){
                            _.forEach(temp, function(v2){
                                if(v.id === v2){
                                    v.value = true;
                                }
                            });
                        });

                    }else if(k === 'price'){

                        var temp = v.split(',');
                        _.forEach(vm.filterCheck.price, function(v){
                            _.forEach(temp, function(v2){
                                if(v.id === v2){
                                    v.value = true;
                                }
                            });
                        });

                    }else if(k === 'type'){

                        var temp = v.split(',');
                        _.forEach(vm.filterCheck.type, function(v){
                            _.forEach(temp, function(v2){
                                if(v.id === v2){
                                    v.value = true;
                                }
                            });
                        });

                    }else  if(k === 'access'){

                        var temp = v.split(',');
                        _.forEach(vm.filterCheck.access, function(v){
                            _.forEach(temp, function(v2){
                                if(v.id === v2){
                                    v.value = true;
                                }
                            });
                        });

                    }
                });

                vm.filterList();
            }
        }

        vm.closeSideBanner = function () {
            vm.sideBanner = false;
        };

         function GetParkingAll() {
            AirtableServices.get_parking_all().then(function (response) {
                var list = response.data.records;

                vm.list = list.slice();
                vm.list_lot = list.slice();
                vm.list_near = list.slice();
                vm.list_popular_parking_cheap = list.slice();
                vm.list_popular_parking_nearby = list.slice();

                _.forEach(vm.list_near, function (v, k) {
                    v.fields.active = false;

                    var lat = parseFloat(v.fields.lat);
                    var lng = parseFloat(v.fields.lng);

                    vm.data_lat_lng.push({lat: lat, lng: lng, id: v.id});
                });
                vm.list_near_master = angular.copy(vm.list_near);
                // console.log('first lot', vm.list_lot)
                // console.log(JSON.stringify(vm.list_lot))
                // console.log('first near', vm.list_near)
                // console.log('first data_lat_lng', vm.data_lat_lng)

                searchFirst();

                // GetEPark();

                // GetPopularAll();

                // navigator.geolocation.getCurrentPosition(successGetLocation, errorGetLocation, options);

            }, function (error) {
                console.log(error)
            }).finally(function () {

            });
        }

        function GetParkingExploreMain() {
            AirtableServices.get_explore_main().then(function (response) {
                vm.list_explore_main = response.data.records;

            }, function (error) {
                console.log(error)
            }).finally(function () {

            });
        }

        function GetPopularAll() {
            AirtableServices.get_popular_all().then(function (response) {
                vm.list_popular = response.data.records;

                _.forEach(vm.list_popular, function (v, k) {

                    var lat = parseFloat(v.fields.lat);
                    var lng = parseFloat(v.fields.lng);

                    vm.data_lat_lng_popular.push({lat: lat, lng: lng, id: v.id});
                });

                // console.log('first pop', vm.list_popular);

                navigator.geolocation.getCurrentPosition(successGetLocationPopular, errorGetLocationPopular, options);

                // checkUrl();

            }, function (error) {
                console.log(error)
            }).finally(function () {

            });
        }

        function GetPopularParkingDistance(popular) {
            var pop_lat = parseFloat(popular.fields.lat);
            var pop_lng = parseFloat(popular.fields.lng);

            var from_latlng = {lat: pop_lat, lng: pop_lng};

            var popular_parking = [];

            _.forEach(vm.list, function (v, k) {

                var lat = parseFloat(v.fields.lat);
                var lng = parseFloat(v.fields.lng);

                popular_parking.push({lat: lat, lng: lng, id: v.id});
            });
            calculateDistancePopular(from_latlng, popular_parking);
        }

        vm.closeErrorMessage = function(){
             vm.message = false;
        };

        var options = {
            enableHighAccuracy: true,
            timeout: 5000,
            maximumAge: 0
        };

        function successGetLocation(pos) {
            vm.flag_get_current_position = true;
            var lat = parseFloat(pos.coords.latitude);
            var lng = parseFloat(pos.coords.longitude);

            var from_latlng = {lat: lat, lng: lng};
            vm.origin = {lat: lat, lng: lng};

            calculateDistance('near', from_latlng, vm.data_lat_lng);

            $location.search('myLoc', 1);

            if(vm.isMobile){
                $timeout(function(){
                    vm.showNearMe = false;
                    GeneralServices.put_object_cookies('place_marker_loc', {lat: lat, lng: lng});
                    $rootScope.$emit('placeMarker', {lat: lat, lng: lng});
                    // GeneralServices.put_object_cookies('place_marker_loc', {lat: 47.627605, lng: -122.285325});
                    // $rootScope.$emit('placeMarker', {lat: 47.627605, lng: -122.285325});

                },100);
            }else{
                $timeout(function(){
                    vm.showNearMe = false;
                    vm.showMap = true;
                    GeneralServices.setShowMap(true);
                    GeneralServices.put_object_cookies('place_marker_loc', {lat: lat, lng: lng});
                    $rootScope.$emit('placeMarker', {lat: lat, lng: lng});
                },100);
            }
        }

        function successGetLocationPopular(pos) {
            vm.flag_get_current_position_popular = true;
            var lat = parseFloat(pos.coords.latitude);
            var lng = parseFloat(pos.coords.longitude);

            var from_latlng = {lat: lat, lng: lng};

            calculateDistance('popular', from_latlng, vm.data_lat_lng_popular);
        }

        function errorGetLocation(err) {

            var message = '';
            // if (vm.first_load) {
            //     vm.first_load = false;
            // } else {
            //     vm.first_load = false;

                switch (err.code) {
                    case 1:
                    case 99:
                        message = "Your browser has denied permission to share your location. Please change your browser settings to allow this page to query your location and try again, or follow one of the other links to find parking in a different way.";
                        break;
                    case 2:
                    case 3:
                        message = "Sorry, We can't find your location at this time. Please try again later.";
                        break;
                    default:
                        message = "Sorry, there was an error finding your location. Please try again later.";
                }

                vm.message = message;

                // $('.overlay .message p').html(message);
                // $('.overlay .message').show();
                // $('.overlay').fadeIn();
            // }
        }

        function errorGetLocationPopular(err) {

            var message = '';
            if (vm.first_load_popular) {
                vm.first_load_popular = false;
            } else {
                vm.first_load_popular = false;

                switch (err.code) {
                    case 1:
                    case 99:
                        message = "Your browser has denied permission to share your location. Please change your browser settings to allow this page to query your location and try again, or follow one of the other links to find parking in a different way.";
                        break;
                    case 2:
                    case 3:
                        message = "Sorry, I can't find your location at this time. Please try again later.";
                        break;
                    default:
                        message = "Sorry, there was an error finding your location. Please try again later.";
                }

                $('.overlay .message p').html(message);
                $('.overlay .message').show();
                $('.overlay').fadeIn();
            }
        }

        vm.reload_page = function () {
            location.reload();
        };

        function calculateDistance(type, objFrom, arrTo) {

            // console.log('aha',type, objFrom, arrTo)

            // vm.view_on_map_false();

            if (type === 'near') {
                vm.list_near = angular.copy(vm.list_near_master);
                _.forEach(vm.list_near, function (v1, k1) {
                    _.forEach(arrTo, function (v2, k2) {
                        if (v1.id === v2.id) {
                            v1.fields.distance = parseFloat(GeneralServices.calculate(objFrom, v2));
                            v1.fields.origin = objFrom;
                            return false;
                        }
                    });
                });
                vm.list_near_master = angular.copy(vm.list_near);
                // console.log('ahoy near',vm.list_near)
            } else if (type === 'popular') {
                _.forEach(vm.list_popular, function (v1, k1) {
                    _.forEach(arrTo, function (v2, k2) {
                        if (v1.id === v2.id) {
                            v1.fields.distance = GeneralServices.calculate(objFrom, v2);
                            v1.fields.origin = objFrom;
                            return false;
                        }
                    });
                });
                // console.log('ahoy pop',vm.list_popular)
            }
        }


        function calculateDistancePopular(objFrom, arrTo) {
            vm.list_popular_parking_cheap = vm.list.slice();

            _.forEach(vm.list_popular_parking_cheap, function (v1, k1) {
                _.forEach(arrTo, function (v2, k2) {
                    if (v1.id === v2.id) {
                        v1.fields.distance = GeneralServices.calculate(objFrom, v2);
                        v1.fields.origin = objFrom;
                        return false;
                    }
                });
            });

            var list = vm.list_popular_parking_cheap.slice();

            vm.list_popular_parking_cheap = _.filter(list, function (o) {
                return o.fields.icon === 'three' && o.fields.distance <= 0.5;
            });

            vm.list_popular_parking_nearby = _.filter(list, function (o) {
                return o.fields.icon !== 'three' && o.fields.distance <= 0.5;
            });
        }

        vm.GetDirection = function(item){
            // $analytics.eventTrack('FindLocation');
            var xhttp = new XMLHttpRequest();
            xhttp.open("GET", "https://insight.adsrvr.org/track/pxl/?adv=1twx34j&ct=0:xtgeocc&fmt=3", false);
            xhttp.send();

            var url = 'https://maps.google.com/maps?daddr=' + encodeURI(item.address) + '&ll=' + item.lat + ',' + item.lng + '&z=16';

            if(typeof item['origin'] !== "undefined"){
                url = 'https://www.google.com/maps/dir/?api=1&origin=' + item.origin.lat + ',' + item.origin.lng + '&destination=' + item.lat + ',' + item.lng + '&z=16';
            }

            window.open(url, "_blank");
        };

        function emitActivePin(data){
            $rootScope.$emit('setPinActive', data);
        }

        vm.CenterByPin = function(item){
            if(vm.fullCompare){
                var obj = item.fields;

                if(obj.origin || obj.distance){
                    $state.go('embeddable-parking', {id:obj.slug,distance:obj.distance,lat:obj.origin.lat,lng:obj.origin.lng}, {});
                }else{
                    $state.go('embeddable-parking', {id:obj.slug}, {});
                }
            }else{
                _.forEach(vm.list_near, function(v,k){
                    v.active = false;
                    if(v.id === item.id){
                        v.active = !v.active;
                        v.fields.active = !v.fields.active;
                    }
                });
                var lng = vm.isMobile ? item.fields.lng : item.fields.lng-0.005;
                $rootScope.$emit('viewMapPin',[item.fields.lat,lng]);

                var tempSend = angular.copy(vm.list_near);
                emitActivePin(tempSend)
            }
        };

        vm.markCompare = function(item){
            _.forEach(vm.list_near, function(v,k){
                if(v.id === item.id){
                    v.fields.active = !v.fields.active;
                }
            });
        };


        vm.GoToParking = function(item){
            var obj = item.fields;

            if(obj.origin || obj.distance){
                $state.go('embeddable-parking', {id:obj.slug,distance:obj.distance,lat:obj.origin.lat,lng:obj.origin.lng}, {});
            }else{
                $state.go('embeddable-parking', {id:obj.slug}, {});
            }
        };

        $rootScope.$on('ActivateListItem', function(event, item) {

            if(!vm.isMobile){
                var scrollSidebar = document.getElementById('scroll-sidebar');
                var e = $('#box-' + item.id);
                scrollSidebar.scrollTo({
                    top: e[0].offsetTop - 90,
                    behavior: 'smooth'
                });
            }

            if(vm.isMobile){
                _.forEach(vm.list_near, function(v,k){
                    if(v.id === item.id){
                        console.log(v.fields);
                        vm.obj = v.fields;
                    }
                });
            }else if(vm.showFilter){
                vm.filterResult();
            }

            if(!vm.showFilter){

                _.forEach(vm.list_near, function(v,k){
                    if(v.id === item.id){
                        v.fields.active = item.active;
                    }
                });
            }


        });

        /*sort = closest, distance
        neighbor = pioneer_square, central_waterfront, retail_core
        price = 1,2
        type = 1,2
        access = 1,2*/

        // $location.search('k', null);
        // $location.search('k', vm.search);

        // var temp = $location.search();

        // function cekLocation(){
        //     var dataSearch = angular.copy($location.search())
        //     console.log(dataSearch)
        // }

        vm.sortKey = "alpha";
        vm.sortFilterKey = "fields.title";

        vm.sort = function (type) {
            $location.search('sort', null);

            if (vm.oldSortType === type) {
                vm.oldSortType = '';
                vm.sortType[0]['value'] = false;
                vm.sortType[1]['value'] = false;
                vm.sortFilterKey = "fields.title";
            } else {
                vm.oldSortType = angular.copy(type);
                if (type === 'distance') {
                    vm.sortType[0]['value'] = true;
                    vm.sortType[1]['value'] = false;
                    vm.sortFilterKey = "fields.distance";
                    $location.search('sort', type);
                } else {
                    vm.sortType[0]['value'] = false;
                    vm.sortType[1]['value'] = true;
                    vm.sortFilterKey = "fields.price";
                    $location.search('sort', type);
                }
            }
        };
        vm.processFilterNeighbor = function(neighbor){
            $location.search('neighbor', null);

            var temp = [];
            _.forEach(vm.filterType, function(v,k){
                if(v.id === neighbor) {
                    v.value = !v.value;
                }
                if(v.value){
                    temp.push(v.id);
                }
            });
            if(temp.length){
                $location.search('neighbor', temp.toString());
            }

        };
        vm.processFilterCheck = function(cat,id){
            $location.search('price', null);
            $location.search('type', null);
            $location.search('access', null);
            var tempPrice = [];
            var tempType = [];
            var tempAccess = [];

            _.forEach(vm.filterCheck.price, function(v,k){
                if(cat === 'price' && v.id === id) {
                    v.value = !v.value;
                }
                if(v.value){
                    tempPrice.push(v.id);
                }
            });
            _.forEach(vm.filterCheck.type, function(v,k){
                if(cat === 'type' && v.id === id) {
                    v.value = !v.value;
                }
                if(v.value){
                    tempType.push(v.id);
                }
            });
            _.forEach(vm.filterCheck.access, function(v,k){
                if(cat === 'access' && v.id === id) {
                    v.value = !v.value;
                }
                if(v.value){
                    tempAccess.push(v.id);
                }
            });
            if(tempPrice.length){
                $location.search('price', tempPrice.toString());
            }
            if(tempType.length){
                $location.search('type', tempType.toString());
            }
            if(tempAccess.length){
                $location.search('access', tempAccess.toString());
            }
        };
        vm.filterList = function(){

            var arr = [];
            var arr2 = [];
            var arr3 = [];
            var arr4 = [];

            var dataSearch = angular.copy($location.search());

            if(typeof dataSearch['neighbor'] !== "undefined"){
                _.forEach(vm.list_near_master, function(v,k){
                    _.forEach(vm.filterType, function(v2,k2){
                        if(v2.value && (v.fields.neighborhood_sorting ===  v2.id)) {
                            arr.push(v);
                        }
                    });
                });
            }else{
                arr = angular.copy(vm.list_near_master);
            }

            if(typeof dataSearch['price'] !== "undefined"){
                _.forEach(arr, function(v,k){
                    _.forEach(vm.filterCheck.price, function(v2,k2){
                        if(v2.value && (v.fields.pricing_filter ===  v2.id)) {
                            arr2.push(v);
                        }
                    });
                });
            }else{
                arr2 = angular.copy(arr)
            }

            if(typeof dataSearch['type'] !== "undefined"){
                _.forEach(arr2, function(v,k){
                    _.forEach(vm.filterCheck.type, function(v2,k2){
                        if(v2.value && (v.fields.parking_filter ===  v2.id)) {
                            arr3.push(v);
                        }
                    });
                });
            }else{
                arr3 = angular.copy(arr2)

            }

            if(typeof dataSearch['access'] !== "undefined"){
                _.forEach(arr3, function(v,k){
                    _.forEach(vm.filterCheck.access, function(v2,k2){
                        if(v2.value && (v.fields.access_filter ===  v2.id)) {
                            arr4.push(v);
                        }
                    });
                });
            }else{
                arr4 = angular.copy(arr3)

            }

            vm.list_near = angular.copy(arr4);
            $rootScope.$emit('filterMarker', vm.list_near);

            // console.log(vm.list_near_master);
            // console.log(1,arr);
            // console.log(2,arr2);
            // console.log(3,arr3);
            // console.log(4,arr4);

        };

        vm.filterResult = function(){
            vm.filterList();
            vm.showFilter = false;
        };
        vm.filterClear = function(){
            $location.search('sort', null);
            $location.search('neighbor', null);
            $location.search('price', null);
            $location.search('type', null);
            $location.search('access', null);

            vm.sortFilterKey = 'fields.title';

            vm.sortType[0]['value'] = false;
            vm.sortType[1]['value'] = false;

            _.forEach(vm.filterType, function(v,k){
                v.value = false;
            });
            _.forEach(vm.filterCheck.price, function(v,k){
                v.value = false;
            });
            _.forEach(vm.filterCheck.type, function(v,k){
                v.value = false;
            });
            _.forEach(vm.filterCheck.access, function(v,k){
                v.value = false;
            });

            vm.list_near = angular.copy(vm.list_near_master);

            $rootScope.$emit('filterMarkerReset', vm.list_near);

            // vm.showFilter = false;
        };
        vm.closeFilter = function(){
            vm.showFilter = false;
        };

    }
})();
