(function () {
    'use strict';

    angular
        .module('dspApp')
        .service('GeneralServices', GeneralServices);

    GeneralServices.$inject=['$cookies'];
    function GeneralServices($cookies) {

        var tempSearch = '';
        var showMap = false;

        var general = {
            get_cookies:get_cookies,
            put_cookies:put_cookies,
            get_object_cookies:get_object_cookies,
            put_object_cookies:put_object_cookies,
            delete_cookies:delete_cookies,
            delete_all_cookies:delete_all_cookies,
            calculate:calculate,
            setTempSearch:setTempSearch,
            getTempSearch:getTempSearch,
            setShowMap:setShowMap,
            getShowMap:getShowMap,

        };

        function setTempSearch(data) {
            tempSearch = data;
        }
        function getTempSearch() {
            return tempSearch;
        }
        function setShowMap(bool) {
            showMap = bool;
        }
        function getShowMap() {
            return showMap;
        }

        function getMiles(i) {
            return i*0.000621371192;
        }
        function getMeters(i) {
            return i*1609.344;
        }
        function deg2rad(deg) {
            return deg * (Math.PI/180)
        }

        return general;

        function get_cookies(name){
            return $cookies.get("" + name + "");
        }
        function put_cookies(name, data){
            $cookies.put("" + name + "", data);
        }
        function get_object_cookies(name){
            return $cookies.getObject("" + name + "");
        }
        function put_object_cookies(name, data){
            $cookies.putObject("" + name + "", data);
        }
        function delete_cookies(name){
            $cookies.remove("" + name + "");
        }
        function delete_all_cookies(){
            var list_cookie = $cookies.getAll();

            _.unset(list_cookie,'_ga');
            _.unset(list_cookie,'_gid');
            _.unset(list_cookie,'io');

            _.forEach(list_cookie, function(v,k){
                $cookies.remove("" + k + "");
            });
        }
        function calculate(from, to){
            var lat1 = from.lat;
            var lon1 = from.lng;

            var lat2 = to.lat;
            var lon2 = to.lng;

            var R = 6371000; // metres
            var v1 = deg2rad(lat1);
            var v2 = deg2rad(lat2);
            var v3 = deg2rad(lat2-lat1);
            var v4 = deg2rad(lon2-lon1);

            var a = Math.sin(v3/2) * Math.sin(v3/2) +
                Math.cos(v1) * Math.cos(v2) *
                Math.sin(v4/2) * Math.sin(v4/2);
            var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1-a));

            return getMiles(R * c).toFixed(1);
        }
    }
})();