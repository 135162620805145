(function () {
    'use strict';

    angular
        .module('dspApp')
        .controller('ExploreCtrl', ExploreCtrl);

    ExploreCtrl.$inject = ['Analytics',
        '$timeout','$analytics',
        '$window','AirtableServices'];
    function ExploreCtrl(Analytics,
                         $timeout,$analytics,
                         $window,AirtableServices) {
        var vm = this;

        // $('html, body').animate({
        //     scrollTop:$('html').offset().top
        // },1000);

        vm.isMobile = $window.innerWidth < 992;

        vm.image_load = 4;
         if($window.innerWidth < 426){
            vm.image_load = 2;
        }else if($window.innerWidth < 992){
            vm.image_load = 3;
        }

        vm.sideBanner = true;

        vm.signMe = false;
        // vm.signMe = true;
        vm.loading = false;
        // vm.loading = true;
        vm.success = false;
        // vm.success = true;
        vm.error = false;
        // vm.error = true;

        vm.error1 = '';
        vm.error2 = '';

        // $("body").removeClass("overflow-hidden");
        $(".ui-container").addClass("relative-100");

        vm.sideBanner = false;
        $timeout(function () {
            vm.sideBanner = false;
        },7000);

        GetParkingExploreMain();
        GetParkingExplore();
        GetBanner();

        function GetParkingExploreMain() {
            AirtableServices.get_explore_main().then(function (response) {
                vm.list_explore_main = response.data.records;

            }, function (error) {
                console.log(error)
            }).finally(function () {

            });
        }

        function GetParkingExplore() {
            AirtableServices.get_explore().then(function (response) {
                vm.list_explore = response.data.records;

            }, function (error) {
                console.log(error)
            }).finally(function () {
                vm.dataLoaded =  true;
            });
        }

        function GetBanner() {
            var filter = "?filterByFormula=placement%3D%22explore%22";

            AirtableServices.get_banner(filter).then(function (response) {
                vm.text_banner = _.get(response.data.records,'[0].fields.text','');
                if(vm.text_banner){
                    vm.sideBanner = response.data.records[0].fields.status === 'active';
                }
            }, function (error) {
                console.log(error)
            }).finally(function () {

            });
        }

        vm.submit = function(){
            vm.success = false;
            vm.error = false;
            vm.loading = true;

            var param = {
                records:[
                    {
                        fields:{
                            name: vm.name,
                            email: vm.email
                        }
                    }
                ]
            };

            AirtableServices.post_sign_in(param).then(function (response) {
                vm.success = true;
            }, function (error) {
                vm.error = true;
                vm.error1 = error.data.error.type;
                vm.error2 = error.data.error.message;
            }).finally(function () {
                vm.loading = false;

            });
        };
    }
})();
