(function () {
    'use strict';

    angular
        .module('dspApp')
        .controller('widgetBannerCtrl', widgetBannerCtrl);

    widgetBannerCtrl.$inject = ['$rootScope','AirtableServices'];

    function widgetBannerCtrl($rootScope,AirtableServices) {
        var vm = this;

        vm.banner = false;

        GetBanner();

        function GetBanner() {
            var filter = "?filterByFormula=placement%3D%22top%22";

            AirtableServices.get_banner(filter).then(function (response) {
                vm.text_banner = _.get(response.data.records,'[0].fields.text','');
                if(vm.text_banner){
                    vm.banner = response.data.records[0].fields.status === 'active';
                }
            }, function (error) {
                console.log(error)
            }).finally(function () {

            });
        }

        vm.closeBanner = function () {
            vm.banner = false;

            $rootScope.$emit('removeBanner');
        };

    }
})();
