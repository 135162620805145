(function () {
    'use strict';

    angular
        .module('dspApp')
        .config(config);

    config.$inject = ['$stateProvider', '$windowProvider'];

    function config($stateProvider, $windowProvider) {

        // var $window = $windowProvider.$get();
        // var isMobile = $window.matchMedia('(max-width: 899px)').matches;
        var isMobile = $(window).width() < 768;

        $stateProvider
            .state('home', {
                url: '/',
                views: {
                    '': {
                        templateUrl: 'app/views/home.html',
                        controller: 'HomeCtrl',
                        controllerAs: 'vm',
                        reloadOnSearch: false
                    },
                    'bannerComp@home': {
                        templateUrl: 'app/views/widget/banner.html',
                        controller: 'widgetBannerCtrl',
                        controllerAs: 'vm',
                    },
                    'headerComp@home': {
                        templateUrl: 'app/views/widget/header.html',
                        controller: 'widgetHeaderCtrl',
                        controllerAs: 'vm',
                    },
                    'footerComp@home': {
                        templateUrl: 'app/views/widget/footer.html',

                    }
                }
            })
            .state('parking', {
            url: '/lot/:id?distance?lat?lng',
            views: {
                '': {
                    templateUrl: 'app/views/parking.html',
                    controller: 'ParkingCtrl',
                    controllerAs: 'vm',
                    },
                    'bannerComp@parking': {
                        templateUrl: 'app/views/widget/banner.html',
                        controller: 'widgetBannerCtrl',
                        controllerAs: 'vm',
                    },
                    'headerComp@parking': {
                        templateUrl: 'app/views/widget/header.html',
                        controller: 'widgetHeaderCtrl',
                        controllerAs: 'vm',
                    },
                    'footerComp@parking': {
                        templateUrl: 'app/views/widget/footer.html',

                    }
                }
            })
            .state('embeddable-map', {
                url: '/embeddable/map',
                views: {
                    '': {
                        templateUrl: 'app/views/embeddable-home.html',
                        controller: 'EmbeddableHomeCtrl',
                        controllerAs: 'vm',
                        reloadOnSearch: false
                    },
                    'bannerComp@embeddable-map': {
                        templateUrl: 'app/views/widget/banner.html',
                        controller: 'widgetBannerCtrl',
                        controllerAs: 'vm',
                    },
                    'headerComp@embeddable-map': {
                        templateUrl: 'app/views/widget/embeddable-header.html',
                        controller: 'widgetEmbeddableHeaderCtrl',
                        controllerAs: 'vm',
                    },
                    'footerComp@embeddable-map': {
                        templateUrl: 'app/views/widget/footer.html',

                    }
                }
            })
            .state('embeddable-parking', {
                url: '/embeddable/lot/:id?distance?lat?lng',
                views: {
                    '': {
                        templateUrl: 'app/views/embeddable-parking.html',
                        controller: 'EmbeddableParkingCtrl',
                        controllerAs: 'vm',
                    },
                    'bannerComp@embeddable-parking': {
                        templateUrl: 'app/views/widget/banner.html',
                        controller: 'widgetBannerCtrl',
                        controllerAs: 'vm',
                    },
                    'headerComp@embeddable-parking': {
                        templateUrl: 'app/views/widget/embeddable-header.html',
                        controller: 'widgetEmbeddableHeaderCtrl',
                        controllerAs: 'vm',
                    },
                    'footerComp@embeddable-parking': {
                        templateUrl: 'app/views/widget/footer.html',

                    }
                }
            })
            .state('explore', {
            url: '/explore',
            views: {
                '': {
                    templateUrl: 'app/views/explore.html',
                    controller: 'ExploreCtrl',
                    controllerAs: 'vm',
                    },
                    'bannerComp@explore': {
                        templateUrl: 'app/views/widget/banner.html',
                        controller: 'widgetBannerCtrl',
                        controllerAs: 'vm',
                    },
                    'headerComp@explore': {
                        templateUrl: 'app/views/widget/header.html',
                        controller: 'widgetHeaderCtrl',
                        controllerAs: 'vm',
                    },
                    'footerComp@explore': {
                        templateUrl: 'app/views/widget/footer.html',

                    }
                }
            })
            .state('about-us', {
            url: '/about-us',
            views: {
                '': {
                    templateUrl: 'app/views/about-us.html',
                    controller: 'AboutUsCtrl',
                    controllerAs: 'vm',
                    },
                    'bannerComp@about-us': {
                        templateUrl: 'app/views/widget/banner.html',
                        controller: 'widgetBannerCtrl',
                        controllerAs: 'vm',
                    },
                    'headerComp@about-us': {
                        templateUrl: 'app/views/widget/header.html',
                        controller: 'widgetHeaderCtrl',
                        controllerAs: 'vm',
                    },
                    'footerComp@about-us': {
                        templateUrl: 'app/views/widget/footer.html',

                    }
                }
            })
            .state('home.shuttle', {
                url: 'shuttle',
                views: {
                    '': {
                        templateUrl: 'app/views/home.html',
                        controller: 'HomeCtrl',
                        controllerAs: 'vm'
                    },
                    'contentmap@home': {
                        templateUrl: function () {
                            if (isMobile) {  //How do I get this information (variable) in here?
                                return 'app/views/shuttle-mobile.html';
                            } else {
                                return 'app/views/shuttle.html'
                            }
                        },
                        controller: 'ShuttleCtrl',
                        controllerAs: 'vm'
                    },
                    'sidebar@home': {
                        templateUrl: 'app/views/sidebar-shuttle.html'
                    }
                }
            })
            .state('home.neighborhoods', {
                url: 'neighborhoods',
                views: {
                    '': {
                        templateUrl: 'app/views/home.html',
                        controller: 'HomeCtrl',
                        controllerAs: 'vm'
                    },
                    'sidebar@home': {
                        templateUrl: 'app/views/sidebar-neighborhoods.html'
                    }
                }
            })
            .state('home.neighborhoods.slug', {
                url: '/:slug',
                views: {
                    '': {
                        templateUrl: 'app/views/home.html',
                        controller: 'HomeCtrl',
                        controllerAs: 'vm'
                    },
                    'sidebar@home': {
                        templateUrl: 'app/views/sidebar-neighborhoods.html'
                    }
                }
            })
            .state('home.hours', {
                url: 'details',
                views: {
                    '': {
                        templateUrl: 'app/views/home.html',
                        controller: 'HomeCtrl',
                        controllerAs: 'vm'
                    },
                    'sidebar@home': {
                        templateUrl: 'app/views/sidebar-hours.html'
                    }
                }
            })
            .state('home.map', {
                url: 'map',
                views: {
                    '': {
                        templateUrl: 'app/views/home.html',
                        controller: 'HomeCtrl',
                        controllerAs: 'vm'
                    },
                    'sidebar@home': {
                        templateUrl: 'app/views/sidebar-map.html'
                    }
                }
            })
            .state('home.near', {
                url: 'near',
                views: {
                    '': {
                        templateUrl: 'app/views/home.html',
                        controller: 'HomeCtrl',
                        controllerAs: 'vm'
                    },
                    'sidebar@home': {
                        templateUrl: 'app/views/sidebar-near.html'
                    }
                }
            })
            .state('home.destinations', {
                url: 'destinations',
                views: {
                    '': {
                        templateUrl: 'app/views/home.html',
                        controller: 'HomeCtrl',
                        controllerAs: 'vm'
                    },
                    'sidebar@home': {
                        templateUrl: 'app/views/sidebar-destinations.html'
                    }
                }
            })
            .state('home.destinations.slug', {
                url: '/:slug',
                views: {
                    '': {
                        templateUrl: 'app/views/home.html',
                        controller: 'HomeCtrl',
                        controllerAs: 'vm'
                    },
                    'sidebar@home': {
                        templateUrl: 'app/views/sidebar-slug.html'
                    }
                }
            })
            // .state('home.parking', {
            //     url: 'lots/:id',
            //     views: {
            //         '': {
            //             templateUrl: 'app/views/home.html',
            //             controller: 'HomeCtrl',
            //             controllerAs: 'vm'
            //         },
            //         'contentmap@home': {
            //             templateUrl: 'app/views/parking.html',
            //             controller: 'ParkingCtrl',
            //             controllerAs: 'vm'
            //         }
            //     }
            // })
            .state('home.neighborhoods.parking', {
                url: '/lots/:id',
                views: {
                    '': {
                        templateUrl: 'app/views/home.html',
                        controller: 'HomeCtrl',
                        controllerAs: 'vm'
                    },
                    'contentmap@home': {
                        templateUrl: 'app/views/parking.html',
                        controller: 'ParkingCtrl',
                        controllerAs: 'vm'
                    }
                }
            })
            .state('home.hours.parking', {
                url: '/lots/:id',
                views: {
                    '': {
                        templateUrl: 'app/views/home.html',
                        controller: 'HomeCtrl',
                        controllerAs: 'vm'
                    },
                    'contentmap@home': {
                        templateUrl: 'app/views/parking.html',
                        controller: 'ParkingCtrl',
                        controllerAs: 'vm'
                    }
                }
            })
            .state('home.map.parking', {
                url: '/lots/:id',
                views: {
                    '': {
                        templateUrl: 'app/views/home.html',
                        controller: 'HomeCtrl',
                        controllerAs: 'vm'
                    },
                    'contentmap@home': {
                        templateUrl: 'app/views/parking.html',
                        controller: 'ParkingCtrl',
                        controllerAs: 'vm'
                    }
                }
            })
            .state('home.near.parking', {
                url: '/lots/:id',
                views: {
                    '': {
                        templateUrl: 'app/views/home.html',
                        controller: 'HomeCtrl',
                        controllerAs: 'vm'
                    },
                    'contentmap@home': {
                        templateUrl: 'app/views/parking.html',
                        controller: 'ParkingCtrl',
                        controllerAs: 'vm'
                    }
                }
            })
            .state('home.destinations.slug.parking', {
                url: '/lots/:id',
                views: {
                    '': {
                        templateUrl: 'app/views/home.html',
                        controller: 'HomeCtrl',
                        controllerAs: 'vm'
                    },
                    'contentmap@home': {
                        templateUrl: 'app/views/parking.html',
                        controller: 'ParkingCtrl',
                        controllerAs: 'vm'
                    }
                }
            })
    }
})();