(function () {
    'use strict';

    angular
        .module('dspApp')
        .controller('ParkingBakCtrl', ParkingBakCtrl);

    ParkingBakCtrl.$inject = ['$stateParams','AirtableServices','Analytics','$interval','$rootScope','$analytics'];
    function ParkingBakCtrl($stateParams,AirtableServices,Analytics,$interval,$rootScope,$analytics) {
        var vm = this;

        $('html, body').animate({
            scrollTop:$('html').offset().top
        },1000);

        var id = $stateParams.id;
        // Analytics.trackPage(id);

        // var getEParkingInterval;
        //
        // function stopGetEParkingInterval(){
        //     if (angular.isDefined(getEParkingInterval)) {
        //         $interval.cancel(getEParkingInterval);
        //         getEParkingInterval = undefined;
        //     }
        // }

        $("body").find(".map-container").addClass("subpage");

        GetParkingData();

        function GetParkingData() {
            AirtableServices.get_parking_all().then(function (response) {
            // AirtableServices.get_parking_by_id(id).then(function (response) {


                var list = response.data.records;

                var obj = _.filter(list, function(o) { return o.fields.slug === id; });

                if(obj.length === 0 ){
                    alert('Data not found.');
                }

                vm.obj = obj[0]['fields'];

                var temp = _.get(vm.obj,'features','');

                if(temp !== ''){
                    var arr_features = temp.split("; ");

                    if(arr_features.length > 1){
                        vm.obj.features = [];
                        for (var i = 0; i < arr_features.length; i++) {
                            vm.obj.features.push(arr_features[i]);
                        }
                    }else if(arr_features.length == 1){
                        vm.obj.features = [];
                        vm.obj.features.push(arr_features[0]);
                    }
                }

                if (vm.obj.taxes_included === "yes"){
                    vm.obj.taxes_included = 'taxes included';
                }else{
                    vm.obj.taxes_included = 'taxes not included';
                }

                vm.obj.rate_1h = vm.obj.rate_1h.toFixed(2);
                vm.obj.rate_2h = vm.obj.rate_2h.toFixed(2);
                vm.obj.rate_3h = vm.obj.rate_3h.toFixed(2);
                vm.obj.rate_4h = vm.obj.rate_4h.toFixed(2);

                // AirtableServices.get_epark().then(function (response2) {
                //     GetEPark(response2);
                // }, function (error) {
                //     console.log(error)
                // }).finally(function() {
                //
                // });

            }, function (error) {
                console.log(error)
            }).finally(function() {

            });
        }

        // $rootScope.$on('updateEPark', function(event, response) {
        //     GetEPark(response);
        // });

        function GetEPark(response) {

            if (vm.obj && typeof vm.obj.epark !== 'undefined') {

                if(vm.obj.epark === 'yes'){

                    _.forEach(response.data, function (v2, k2) {

                        if (v2.Id == vm.obj.GarageID) {

                            if (v2.OutOfService === true) {
                                vm.obj.out_of_service = true;
                                vm.obj.percentage = '0%';
                                return false;
                            } else {
                                vm.obj.vacant = v2.VacantSpaces;
                                vm.obj.out_of_service = false;

                                if (vm.obj.total_space < v2.VacantSpaces) {

                                    vm.obj.total_space = 0;

                                } else {

                                    if(v2.VacantSpaces < 0){
                                        vm.obj.percentage = '100%';
                                    }else{
                                        var num = Math.floor(((vm.obj.total_space - v2.VacantSpaces) / vm.obj.total_space) * 100);
                                        vm.obj.percentage = String(num) + '%';
                                    }

                                }

                                return false;
                            }
                        }
                    });
                }
            }
        }

        vm.GetDirection = function(){
            $analytics.eventTrack('FindLocation');
            var xhttp = new XMLHttpRequest();
            xhttp.open("GET", "https://insight.adsrvr.org/track/pxl/?adv=1twx34j&ct=0:xtgeocc&fmt=3", false);
            xhttp.send();
            var url = 'https://maps.google.com/maps?daddr=' + encodeURI(vm.obj.address) + '&ll=' + vm.obj.lat + ',' + vm.obj.lng + '&z=16';
            window.open(url, "_blank");
        };

        vm.goBack = function(){
            window.history.back();
        };
    }
})();
