(function () {
    'use strict';

    angular
        .module('dspApp')
        .controller('widgetHeaderCtrl', widgetHeaderCtrl);

    widgetHeaderCtrl.$inject = ['$window','$state',
    'GeneralServices'];

    function widgetHeaderCtrl($window,$state,
                              GeneralServices) {
        var vm = this;

        var curr_state = $state.current.name;

        if(curr_state === 'home' || curr_state === 'parking'){
            vm.curr_state = 'find';
        }else if(curr_state === 'explore'){
            vm.curr_state = 'explore';
        }

        vm.isMobile = $window.innerWidth < 992;

        function getRndInteger(min, max) {
            return Math.floor(Math.random() * (max - min + 1) ) + min;
        }

        // vm.logo_array = ['img/octopus.png','img/tower.png'];
        vm.logo_array = ['img/octopus.png','img/smith-tower.svg'];

        if(vm.isMobile){
            vm.logo_url = vm.logo_array[1];
            vm.randomNumber = 1;
        }else{
            vm.randomNumber = getRndInteger(1,2)-1;
            vm.logo_url = vm.logo_array[vm.randomNumber];
        }

        vm.goHome = function(){
            GeneralServices.setTempSearch(false);
            $state.go('home', {}, {reload: true});
        };
        vm.goExplore = function(){
            $state.go('explore', {}, {reload: true});
        };

    }
})();
