(function () {
    'use strict';

    angular
        .module('dspApp')
        .controller('SearchMapCtrl', SearchMapCtrl);

    SearchMapCtrl.$inject = ['$rootScope','GeneralServices','$location'];
    function SearchMapCtrl($rootScope,GeneralServices,$location) {
        var vm = this;

        if(GeneralServices.getTempSearch()){
            vm.search = GeneralServices.getTempSearch().getPlace().formatted_address;
            placeMarkerFinal(GeneralServices.getTempSearch());
        }

        if(vm.search){
            $rootScope.$emit('setOnlyShowMapOnHome');
        }

        vm.toggle = function(){
            console.log(1);
        };

        function placeMarkerFinal(data){
            var loc = data.getPlace().geometry.location;

            var lat = parseFloat(loc.lat());
            var lng = parseFloat(loc.lng());

            $rootScope.$emit('placeMarker', {lat: lat, lng: lng});
        }

        vm.placeMarker = function () {
            $location.search('myLoc', null);
            $rootScope.$emit('resetActive');

            GeneralServices.setTempSearch(this);
            $rootScope.$emit('setShowMapOnHome');
            if(GeneralServices.getShowMap()){
                placeMarkerFinal(this)
            }else{
                // GeneralServices.setTempSearch(this);
                // $rootScope.$emit('setShowMapOnHome');
            }
            $rootScope.$emit('setShowNearMe');
        };

        vm.clickFilter = function () {
            $rootScope.$emit('toggleFilter', true);
        };
        $rootScope.$on('removeSearch', function(event) {
            vm.search = '';
        });

        // $rootScope.$on('setTempSearchAgain', function (event) {
        //     placeMarkerFinal(GeneralServices.getTempSearch())
        // });

    }
})();