(function () {
    'use strict';

    angular
        .module('dspApp')
        .controller('MapHomeCtrl', MapHomeCtrl);

    MapHomeCtrl.$inject = ['AirtableServices','GeneralServices','ENV',
        '$location','$timeout','$state',
        '$scope','$rootScope','NgMap',
        '$window','Analytics',
        '$stateParams'];
    function MapHomeCtrl(AirtableServices,GeneralServices,ENV,
                     $location,$timeout,$state,
                     $scope,$rootScope,NgMap,
                     $window,Analytics,
                     $stateParams) {
        var vm = this;

        vm.isMobile = $window.innerWidth < 992;

        vm.googleMapsUrl = ENV.GMAPS_URL;

        GetParkingAll();

        // var default_center = [47.604,-122.335];
        var default_center = [47.601,-122.335];
        vm.center = default_center;
        vm.zoom = 16;

        vm.map_full = true;
        vm.draggable = false;
        NgMap.getMap().then(function(map) {
            vm.map = map;

            resizeMap();
        });

        function resizeMap(){
            google.maps.event.trigger(vm.map, 'resize');
        }

        var bounds = new google.maps.LatLngBounds();

        vm.data = [];
        vm.data_lat_lng = [];

        var marker_regular = {
            url: 'img/pin-p-s.png',
        };
        var marker_three = {
            url: 'img/pin-3-s.png',
        };
        var marker_four = {
            url: 'img/pin-4-s.png',
        };
        var marker_flat = {
            url: 'img/pin-f-s.png',
        };

        var marker_regular_active = {
            url: 'img/pin-p-a.png',
        };
        var marker_three_active = {
            url: 'img/pin-3-a.png',
        };
        var marker_four_active = {
            url: 'img/pin-4-a.png',
        };
        var marker_flat_active = {
            url: 'img/pin-f-a.png',
        };
        var marker_epark = {
            url: 'img/marker-sprites.png',
            size: [35, 45],
            origin: [0, 45],
            anchor: [18, 45]
        };
        var marker_four_point_five = {
            url: 'img/marker-four-point-five.png',
            // scaledSize: [35, 45]
        };
        var marker_shadow = {
            url: 'img/marker-sprites.png',
            size: [45, 25],
            origin: [0, 135],
            anchor: [9, 25]
        };

        vm.icon_regular = marker_regular;
        vm.icon_three = marker_three;
        vm.icon_four = marker_four;
        vm.icon_flat = marker_flat;

        vm.icon_regular_active = marker_regular_active;
        vm.icon_three_active = marker_three_active;
        vm.icon_four_active = marker_four_active;
        vm.icon_flat_active = marker_flat_active;

        vm.icon_epark = marker_epark;
        vm.icon_four_point_five = marker_four_point_five;

        function GetParkingAll() {
            AirtableServices.get_parking_all().then(function (response) {
                vm.data = response.data.records;
                ProcessData();

                vm.center = default_center;

            }, function (error) {
                console.log(error)
            });
        }

        function ProcessData(){
            _.forEach(vm.data, function (v, k) {

                var lat = parseFloat(v.fields.lat);
                var lng = parseFloat(v.fields.lng);

                var icon_temp = vm.icon_regular;

                // var zIndex = 500;
                var zIndex = 100;

                if (v.fields.icon === 'flat'){
                    icon_temp = vm.icon_flat;
                    zIndex = 300;

                }else if (v.fields.icon === 'three'){
                    icon_temp = vm.icon_three;
                    // zIndex = 100;
                    zIndex = 500;

                }else if (v.fields.icon === 'four') {
                    icon_temp = vm.icon_four;
                    // zIndex = 200;
                    zIndex = 400;

                }else if (v.fields.icon === '4.50') {
                    icon_temp = vm.icon_four_point_five;
                    // zIndex = 200;
                    zIndex = 350;

                }else if (v.fields.icon === 'epark') {
                    icon_temp = vm.icon_epark;
                    // zIndex = 400;
                    zIndex = 200;
                }

                v.position = [lat, lng];
                v.icon = icon_temp;
                v.zIndex = zIndex;

                vm.data_lat_lng.push({lat:lat, lng:lng, id:v.id});
            });
        }

        vm.GoToParking = function(evt,item){
            var obj = item.fields;
            $state.go('parking', {id:obj.slug}, {});
        };

        var apple_map  = [
            {
                "featureType": "landscape.man_made",
                "elementType": "geometry",
                "stylers": [
                    {
                        "color": "#f7f1df"
                    }
                ]
            },
            {
                "featureType": "landscape.natural",
                "elementType": "geometry",
                "stylers": [
                    {
                        "color": "#d0e3b4"
                    }
                ]
            },
            {
                "featureType": "landscape.natural.terrain",
                "elementType": "geometry",
                "stylers": [
                    {
                        "visibility": "off"
                    }
                ]
            },
            {
                "featureType": "poi",
                "elementType": "labels",
                "stylers": [
                    {
                        "visibility": "on"
                    }
                ]
            },
            {
                "featureType": "poi.business",
                "elementType": "all",
                "stylers": [
                    {
                        "visibility": "off"
                    }
                ]
            },
            {
                "featureType": "poi.medical",
                "elementType": "geometry",
                "stylers": [
                    {
                        "color": "#fbd3da"
                    }
                ]
            },
            {
                "featureType": "poi.park",
                "elementType": "geometry",
                "stylers": [
                    {
                        "color": "#bde6ab"
                    }
                ]
            },
            {
                "featureType": "road",
                "elementType": "geometry.stroke",
                "stylers": [
                    {
                        "visibility": "on"
                    }
                ]
            },
            {
                "featureType": "road",
                "elementType": "labels",
                "stylers": [
                    {
                        "visibility": "on"
                    }
                ]
            },
            {
                "featureType": "road.highway",
                "elementType": "geometry.fill",
                "stylers": [
                    {
                        "color": "#ffe15f"
                    }
                ]
            },
            {
                "featureType": "road.highway",
                "elementType": "geometry.stroke",
                "stylers": [
                    {
                        "color": "#efd151"
                    }
                ]
            },
            {
                "featureType": "road.arterial",
                "elementType": "geometry.fill",
                "stylers": [
                    {
                        "color": "#ffffff"
                    }
                ]
            },
            {
                "featureType": "road.local",
                "elementType": "geometry.fill",
                "stylers": [
                    {
                        "color": "black"
                    }
                ]
            },
            {
                "featureType": "transit.station.airport",
                "elementType": "geometry.fill",
                "stylers": [
                    {
                        "color": "#cfb2db"
                    }
                ]
            },
            {
                "featureType": "water",
                "elementType": "geometry",
                "stylers": [
                    {
                        "color": "#a2daf2"
                    }
                ]
            }
        ];

        var default_map = [
            {
                featureType: "road.highway",
                elementType: "geometry.fill",
                stylers: [
                    { color: "#b2b2b2" }
                ]
            },
            {
                featureType: "road.highway",
                elementType: "geometry.stroke",
                stylers: [
                    { color: "#969696" },
                    { weight: 0.8 }
                ]
            },
            {
                featureType: "road.highway",
                elementType: "labels.text.stroke",
                stylers: [
                    { color: "#b2b2b2" }
                ]
            },
            {
                elementType: "labels.text.fill",
                stylers: [
                    { color: "#2f2f2f" }
                ]
            },
            {
                featureType: "road.arterial",
                elementType: "geometry",
                stylers: [
                    { color: "#c7c7c7" }
                ]
            },
            {
                featureType: "road.arterial",
                elementType: "labels.text.stroke",
                stylers: [
                    { color: "#c7c7c7" }
                ]
            },
            {
                featureType: "water",
                elementType: "geometry.fill",
                stylers: [
                    { color: "#afc9d1" }
                ]
            },
            {
                featureType: "transit.line",
                elementType: "geometry.fill",
                stylers: [
                    { color: "#b2b2b2" }
                ]
            },
            {
                featureType: "transit.line",
                elementType: "labels.text.stroke",
                stylers: [
                    { color: "#b2b2b2" }
                ]
            },
            {
                featureType: "transit",
                elementType: "labels.icon",
                stylers: [
                    { saturation: -100 },
                    { gamma: 0.84 }
                ]
            },
            {
                featureType: "poi",
                elementType: "geometry.fill",
                stylers: [
                    { color: "#b2b2b2" }
                ]
            },
            {
                featureType: "poi",
                elementType: "labels",
                stylers: [
                    { visibility: "off" }
                ]
            },
            {
                featureType: "transit.station.airport",
                elementType: "labels",
                stylers: [
                    { visibility: "off" }
                ]
            },
            {
                featureType: "road",
                elementType: "labels.icon",
                stylers: [
                    { saturation: -100 },
                    { gamma: 0.78 }
                ]
            }
        ];

        vm.getStyle = function(){
            return apple_map;
        };
    }
})();