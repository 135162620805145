(function () {
    'use strict';

    angular
        .module('dspApp')
        .controller('ShuttleCtrl', ShuttleCtrl);

    ShuttleCtrl.$inject = ['GeneralServices', '$state', '$scope', '$location', '$rootScope', 'NgMap', '$interval', '$timeout'];

    function ShuttleCtrl(GeneralServices, $state, $scope, $location, $rootScope, NgMap, $interval, $timeout) {
        var vm = this;

        NgMap.getMap().then(function (map) {
            vm.map = map;
            vm.zoomChanged = function (e) {
                var z = map.getZoom();
                if (z < 16) {
                    changePosition(2, [47.61320769, -122.35395534]);
                    changePosition(4, [47.61001963, -122.34858696]);
                    changePosition(6, [47.603914,-122.340084]);
                    changePosition(8, [47.603047,-122.339253]);
                    changePosition(9, [47.60365,-122.337627]);
                } else if (z === 16) {
                    changePosition(2, [47.61366, -122.353714]);
                    changePosition(4, [47.610533, -122.348227]);
                    changePosition(6, [47.604088,-122.339499]);
                    changePosition(8, [47.603441,-122.338889]);
                    changePosition(9, [47.603826,-122.337974]);
                } else if (z >= 17) {
                    changePosition(2, [47.613868, -122.353526]);
                    changePosition(4, [47.610715, -122.348017]);
                    changePosition(6, [47.604247,-122.339258]);
                    changePosition(8, [47.603557,-122.33861]);
                    changePosition(9, [47.603826,-122.337974]);
                }
            }
        });

        function changePosition(id, pos){
            id = id.toString();
            _.forEach(vm.data, function(v,k){
                if (v.id === id){
                    v.position = pos;
                }
            });
        }

        vm.center = [47.60968152,-122.33703509];
        vm.zoom = 15;
        vm.path_north = [
            [47.604509, -122.339102], //1 F
            [47.606897, -122.341317], //2
            [47.607697, -122.342043], //3
            [47.607851, -122.342401], //4
            [47.61502959, -122.35495263], //5
            [47.61976974, -122.34884699], //6
            [47.61975166, -122.34762731], //7
            [47.61817813, -122.34763933], //8
            [47.61812976, -122.34767353], //9
            [47.61713245, -122.3489011], //10
            [47.6182498, -122.3508001] //11
        ];
        vm.path_south = [
            [47.604509, -122.339102], //1 F
            [47.601664,-122.33651], //2
            [47.601733,-122.336266], //3
            [47.600867,-122.335661], //5
            [47.598305,-122.335604], //5 I
            [47.59830914,-122.33158006], //6
            [47.60123826,-122.33156483], //7
            [47.60172838,-122.33200471], //8
            [47.60174526,-122.33551362], //9 H
            [47.601733,-122.336266] //10
        ];
        vm.path_east = [
            [47.604509, -122.339102], //1 F
            [47.605397,-122.336917], //2
            [47.607733,-122.331371], //3
            [47.612144,-122.335388], //4
            [47.609825,-122.340942], //5
            [47.605397,-122.336917] //6
        ];

        vm.template = 'info-window-template.html';

        var c_marker = [
            {url: 'img/pin-point/a.png'},  //0
            {url: 'img/pin-point/b.png'},  //1
            {url: 'img/pin-point/c.png'},  //2
            {url: 'img/pin-point/d.png'},  //3
            {url: 'img/pin-point/e.png'},  //4
            {url: 'img/pin-point/f.png'},  //5
            {url: 'img/pin-point/f2.png'}, //6
            {url: 'img/pin-point/g.png'},  //7
            {url: 'img/pin-point/g2.png'}, //8
            {url: 'img/pin-point/h.png'},  //9
            {url: 'img/pin-point/i.png'},  //10
            {url: 'img/pin-point/j.png'},  //11
            {url: 'img/pin-point/k.png'},  //12
            {url: 'img/pin-point/l.png'}   //13
        ];

        _.forEach(c_marker, function (v, k) {
            v.scaledSize = [35, 35];
        });

        if ($(window).width() < 768 ){

            vm.center = [47.60968152,-122.34220329];
            vm.zoom = 14;

            _.forEach(c_marker,function(v,k){
                v.scaledSize = [25,25];
            });
            
        }

        vm.data =[
            {
                position : [47.61976974,-122.34884699],
                id : '1',
                icon : c_marker[0]
            },
            {
                position : [47.61320769,-122.35395534],
                id : '2',
                icon : c_marker[1]
            },
            {
                position : [47.61420247,-122.35304193],
                id : '3',
                icon : c_marker[2]
            },
            {
                position : [47.61001963, -122.34858696],
                id : '4',
                icon : c_marker[3]
            },
            {
                position : [47.61107565, -122.34747116],
                id : '5',
                icon : c_marker[4]
            },
            {
                position : [47.603914,-122.340084],
                id : '6',
                icon : c_marker[5]
            },
            {
                position : [47.604594,-122.338555],
                id : '7',
                icon : c_marker[6]
            },
            {
                position : [47.603047,-122.339253],
                id : '8',
                icon : c_marker[7]
            },
            {
                position : [47.60365,-122.337627],
                id : '9',
                icon : c_marker[8]
            },
            {
                position : [47.60175034, -122.33520985],
                id : '10',
                icon : c_marker[9]
            },
            {
                position : [47.59830914,-122.33158006],
                id : '11',
                icon : c_marker[10]
            },
            {
                position : [47.610783, -122.334089],
                id : '12',
                icon : c_marker[11]
            },
            {
                position : [47.609938, -122.340788],
                id : '13',
                icon : c_marker[12]
            },
            {
                position : [47.606955,-122.341386],
                id : '14',
                icon : c_marker[13]
            }
        ];


        if ($(window).width() < 768) {
            vm.mobile_shuttle = true;
        }

        vm.openNavMobile = function(){
            document.getElementById("myNav").style.width = "100%";
        };

        vm.ScrollToMobile = function(){
            $(".map-container").animate({
                scrollTop: $('#shuttle-legend-mobile').offset().top
            },1000);
        };

        vm.goBack = function () {
            $state.go('home', {}, {reload: true});
        };

        vm.showInfo = function (event,item){
            vm.curr = item;
            vm.map.showInfoWindow('window-template',item.id);
        };

        vm.getStyle = function () {
            return [
                {
                    "featureType": "administrative.land_parcel",
                    "elementType": "labels",
                    "stylers": [
                        {
                            "visibility": "off"
                        }
                    ]
                },
                {
                    "featureType": "poi",
                    "elementType": "labels.text",
                    "stylers": [
                        {
                            "visibility": "off"
                        }
                    ]
                },
                {
                    "featureType": "poi.business",
                    "stylers": [
                        {
                            "visibility": "off"
                        }
                    ]
                },
                {
                    "featureType": "road",
                    "elementType": "labels.icon",
                    "stylers": [
                        {
                            "visibility": "off"
                        }
                    ]
                }
            ]
        };

    }
})();