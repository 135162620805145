(function () {
    'use strict';

    angular
        .module('dspApp')
        .controller('ParkingCtrl', ParkingCtrl);

    ParkingCtrl.$inject = ['$stateParams','AirtableServices',
        'Analytics','$interval',
        '$rootScope','$analytics',
        '$window','$state'];
    function ParkingCtrl($stateParams,AirtableServices,
                         Analytics,$interval,
                         $rootScope,$analytics,
                         $window,$state) {
        var vm = this;

        // $('html, body').animate({
        //     scrollTop:$('html').offset().top
        // },1000);

        vm.isMobile = $window.innerWidth < 992;

        vm.id = $stateParams.id;
        vm.distance = $stateParams.distance;
        vm.lat = $stateParams.lat;
        vm.lng = $stateParams.lng;

        // $("body").removeClass("overflow-hidden");

        $(".ui-container").addClass("relative-100");

        vm.loading = true;

        vm.goBack = function(){
            window.history.back();
        };

        // Analytics.trackPage(id);

        // var getEParkingInterval;
        //
        // function stopGetEParkingInterval(){
        //     if (angular.isDefined(getEParkingInterval)) {
        //         $interval.cancel(getEParkingInterval);
        //         getEParkingInterval = undefined;
        //     }
        // }

        // $("body").find(".map-container").addClass("subpage");

        GetParkingData();

        function GetParkingData() {
            AirtableServices.get_parking_all().then(function (response) {
            // AirtableServices.get_parking_by_id(id).then(function (response) {


                var list = response.data.records;

                var obj = _.filter(list, function(o) { return o.fields.slug === vm.id; });

                if(obj.length === 0 ){
                    alert('Data not found.');
                }

                vm.obj = obj[0]['fields'];

                var temp = _.get(vm.obj,'features','');

                if(temp !== ''){
                    var arr_features = temp.split("; ");

                    if(arr_features.length > 1){
                        vm.obj.features = [];
                        for (var i = 0; i < arr_features.length; i++) {
                            vm.obj.features.push(arr_features[i]);
                        }
                    }else if(arr_features.length == 1){
                        vm.obj.features = [];
                        vm.obj.features.push(arr_features[0]);
                    }
                }

                if (vm.obj.taxes_included === "yes"){
                    vm.obj.taxes_included = 'taxes included';
                }else{
                    vm.obj.taxes_included = 'taxes not included';
                }


                if (vm.obj.parking_filter === "covered"){
                    vm.obj.parking_filter = 'Covered / Garage';
                }else{
                    vm.obj.parking_filter = 'Uncovered / Lot';
                }

                if (vm.obj.access_filter === "elevator"){
                    vm.obj.access_filter = 'Elevator';
                }else{
                    vm.obj.access_filter = 'One level / No stairs';
                }

                vm.obj.rate_1h = vm.obj.rate_1h.toFixed(2);
                vm.obj.rate_2h = vm.obj.rate_2h.toFixed(2);
                vm.obj.rate_3h = vm.obj.rate_3h.toFixed(2);
                vm.obj.rate_4h = vm.obj.rate_4h.toFixed(2);

                vm.loading = false;

                // AirtableServices.get_epark().then(function (response2) {
                //     GetEPark(response2);
                // }, function (error) {
                //     console.log(error)
                // }).finally(function() {
                //
                // });

            }, function (error) {
                console.log(error)
                vm.loading = false;
            }).finally(function() {

            });
        }

        // $rootScope.$on('updateEPark', function(event, response) {
        //     GetEPark(response);
        // });

        vm.GetDirection = function(){
            $analytics.eventTrack('FindLocation');
            var xhttp = new XMLHttpRequest();
            xhttp.open("GET", "https://insight.adsrvr.org/track/pxl/?adv=1twx34j&ct=0:xtgeocc&fmt=3", false);
            xhttp.send();

            var url = 'https://maps.google.com/maps?daddr=' + encodeURI(vm.obj.address) + '&ll=' + vm.obj.lat + ',' + vm.obj.lng + '&z=16';

            if(vm.lat && vm.lng){
                url = 'https://www.google.com/maps/dir/?api=1&origin=' + vm.lat + ',' + vm.lng + '&destination=' + vm.obj.lat + ',' + vm.obj.lng + '&z=16';
            }

            window.open(url, "_blank");
        };

        vm.goBack = function(){
            // window.history.back();
            $state.go('home', {}, {});
        };
    }
})();
