(function () {
    'use strict';

    angular
        .module('dspApp')
        .controller('AboutUsCtrl', AboutUsCtrl);

    AboutUsCtrl.$inject = ['Analytics',
        '$timeout','$analytics',
        '$window','AirtableServices'];
    function AboutUsCtrl(Analytics,
                         $timeout,$analytics,
                         $window,AirtableServices) {
        var vm = this;

        vm.isMobile = $window.innerWidth < 992;

        $(".ui-container").addClass("relative-100");

        GetAboutUs();

        function GetAboutUs() {
            AirtableServices.get_about_us().then(function (response) {
                vm.list_about_us = response.data.records;

            }, function (error) {
                console.log(error)
            }).finally(function () {

            });
        }

    }
})();
