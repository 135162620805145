(function () {
    'use strict';

    angular
        .module('dspApp')
        .controller('MapCtrl', MapCtrl);

    MapCtrl.$inject = ['AirtableServices','GeneralServices','ENV',
        '$location','$timeout','$state',
        '$scope','$rootScope','NgMap',
        '$window','Analytics',
        '$stateParams'];
    function MapCtrl(AirtableServices,GeneralServices,ENV,
                     $location,$timeout,$state,
                     $scope,$rootScope,NgMap,
                     $window,Analytics,
                     $stateParams) {
        var vm = this;

        vm.isMobile = $window.innerWidth < 992;

        vm.googleMapsUrl = ENV.GMAPS_URL;

        var place_marker = GeneralServices.get_object_cookies('place_marker');
        var place_marker_loc = GeneralServices.get_object_cookies('place_marker_loc');

        vm.myLoc = false;

        if($stateParams.id){
            var id = $stateParams.id;
            GetParkingById(id);
        }else{
            var dataSearch = angular.copy($location.search());

            if(!_.isEmpty(dataSearch)){
                if(typeof dataSearch['myLoc'] !== "undefined"){
                    vm.myLoc = true;
                }

                var temp_all = true;

                _.forEach(dataSearch, function(v,k){
                    if(k === 'sort'){
                        temp_all = false;
                    }else if(k === 'neighbor'){
                        temp_all = false;
                    }else if(k === 'price'){
                        temp_all = false;
                    }else if(k === 'type'){
                        temp_all = false;
                    }else  if(k === 'access'){
                        temp_all = false;
                    }
                });

                if(temp_all){
                    GetParkingAll();
                }
            }else{
                GetParkingAll();
            }
        }

        // $( "html" ).remove( "#quick-zoom" );

        vm.central_waterfront = [47.607, -122.338];
        vm.pioneer_square = [47.602, -122.335];
        vm.retail_core = [47.610, -122.336];
        var default_center = [47.604,-122.339];
        if(vm.isMobile){
            default_center = [47.604,-122.335]
        }

        vm.center = default_center;
        vm.zoom = 15;

        vm.map_full = true;
        vm.draggable = false;
        NgMap.getMap().then(function(map) {
            vm.map = map;

            resizeMap();
        });

        function resizeMap(){
            google.maps.event.trigger(vm.map, 'resize');
        }

        var bounds = new google.maps.LatLngBounds();

        // var path = $location.path();
        // var temp_check = _.includes(path,'lots');

        // $timeout(function(){
        //     if(temp_check){
        //         vm.map_full = false;
        //         // var id = _.replace(path, '/lots/', '');
        //         var id = $state.params.id;
        //         GetParkingById(id);
        //     }else{
        //         GetParkingAll();
        //     }
        // },500);

        vm.data = [];
        vm.data_lat_lng = [];

        // vm.icon_flat = 'img/key-flat.png';
        // vm.icon_low = 'img/key-low.png';
        // vm.icon_regular = 'img/key-regular.png';
        // vm.icon_epark = 'img/key-epark.png';

        var marker_regular = {
            url: 'img/pin-p-s.png',
        };
        var marker_three = {
            url: 'img/pin-3-s.png',
        };
        var marker_four = {
            url: 'img/pin-4-s.png',
        };
        var marker_flat = {
            url: 'img/pin-f-s.png',
        };

        var marker_regular_active = {
            url: 'img/pin-p-a.png',
        };
        var marker_three_active = {
            url: 'img/pin-3-a.png',
        };
        var marker_four_active = {
            url: 'img/pin-4-a.png',
        };
        var marker_flat_active = {
            url: 'img/pin-f-a.png',
        };
        var marker_epark = {
            url: 'img/marker-sprites.png',
            size: [35, 45],
            origin: [0, 45],
            anchor: [18, 45]
        };
        var marker_four_point_five = {
            url: 'img/marker-four-point-five.png',
            // scaledSize: [35, 45]
        };
        var marker_shadow = {
            url: 'img/marker-sprites.png',
            size: [45, 25],
            origin: [0, 135],
            anchor: [9, 25]
        };

        // if(vm.isMobile){
        //     marker_regular = {
        //         url: 'img/pin-p-s.png',
        //     };
        //     marker_three = {
        //         url: 'img/pin-3-s.png',
        //     };
        //     marker_four = {
        //         url: 'img/pin-4-s.png',
        //     };
        //     marker_flat = {
        //         url: 'img/pin-p-s.png',
        //     };
        // }

        // if ($(window).width() < 768 && !temp_check){
        //     marker_regular = {
        //         url: 'img/marker-sprites-tiny.png',
        //         size: [16, 21],
        //         origin: [0, 0],
        //         anchor: [9, 22]
        //     };
        //     marker_three = {
        //         url: 'img/marker-sprites-tiny.png',
        //         size: [16, 21],
        //         origin: [0, 42],
        //         anchor: [9, 22]
        //     };
        //     marker_four = {
        //         url: 'img/marker-sprites-tiny.png',
        //         size: [16, 21],
        //         origin: [0, 118],
        //         anchor: [9, 22]
        //     };
        //     marker_flat = {
        //         url: 'img/marker-sprites-tiny.png',
        //         size: [16, 21],
        //         origin: [0, 96],
        //         anchor: [9, 22]
        //     };
        //     marker_epark = {
        //         url: 'img/marker-sprites-tiny.png',
        //         size: [16, 21],
        //         origin: [0, 21],
        //         anchor: [9, 22]
        //     };
        //     marker_four_point_five = {
        //         url: 'img/marker-four-point-five.png',
        //         scaledSize: [17, 21]
        //     };
        //     marker_shadow = {
        //         url: 'img/marker-sprites-tiny.png',
        //         size: [21, 12],
        //         origin: [0, 63],
        //         anchor: [5, 12]
        //     };
        // }

        vm.icon_regular = marker_regular;
        vm.icon_three = marker_three;
        vm.icon_four = marker_four;
        vm.icon_flat = marker_flat;

        vm.icon_regular_active = marker_regular_active;
        vm.icon_three_active = marker_three_active;
        vm.icon_four_active = marker_four_active;
        vm.icon_flat_active = marker_flat_active;

        vm.icon_epark = marker_epark;
        vm.icon_four_point_five = marker_four_point_five;

        vm.toggle = function(){
            console.log(1);
        };

        vm.getPos = function (event) {
            if(vm.map_full && vm.draggable){

                var lat = parseFloat(event.latLng.lat());
                var lng = parseFloat(event.latLng.lng());

                vm.latlng = [lat, lng];
                vm.from_latlng = {lat:lat, lng:lng};
                calculateDistance(vm.from_latlng,vm.data_lat_lng);

                $scope.$emit('viewOnMapFeedback', true);
            }
        };

        $rootScope.$on('placeMarker', function(event, data) {
            var lat = parseFloat(data.lat);
            var lng = parseFloat(data.lng);
            var lng_center = vm.isMobile ? lng : lng-0.003;

            vm.latlng = [lat, lng];
            vm.center = [lat, lng_center];
            // vm.center = [lat, lng];
            vm.from_latlng = {lat:lat, lng:lng};
            vm.zoom = 17;

            calculateDistance(vm.from_latlng, vm.data_lat_lng);

            if(vm.myLoc && place_marker_loc && vm.isMobile){
                var temp_lat = parseFloat(place_marker_loc.lat);
                var temp_lng = parseFloat(place_marker_loc.lng);
                temp_lng = vm.isMobile ? temp_lng : temp_lng-0.003;

                vm.latlng = [temp_lat,temp_lng];
                vm.center = [temp_lat,temp_lng];
                vm.zoom = 17;
            }
        });

        $rootScope.$on('filterMarker', function(event, data) {
            vm.data = data;
            ProcessData();
        });
        $rootScope.$on('filterMarkerReset', function(event, data) {
            vm.data = data;
            ProcessData();
            vm.center = default_center;
            vm.zoom = 15;
        });

        $rootScope.$on('viewOnMap', function(event, data) {
            vm.draggable = data;
            if ($(window).width() < 768){
                vm.zoom = 13;
            }
        });

        $rootScope.$on('viewMapNeigborhood', function(event, data) {
            vm.go_center(data);
        });

        vm.go_center = function(data){

            // Analytics.trackEvent('quick-zoom', 'click', data);
            vm.zoom = 16;
            if (data === 'pioneer-square'){
                vm.center = vm.pioneer_square;
            }else if(data === 'central-waterfront'){
                vm.center = vm.central_waterfront;
            }else{
                vm.center = vm.retail_core;
            }
        };

        $rootScope.$on('viewMapPin', function(event, data) {
            vm.go_center_pin(data);
        });
        vm.go_center_pin = function(data){
            // Analytics.trackEvent('quick-zoom', 'click', data);
            vm.zoom = 17;

            var lat = parseFloat(data[0]);
            lat = vm.isMobile ? lat-0.00005 : lat;

            var lng = parseFloat(data[1]);
            lng = vm.isMobile ? lng : lng+0.003;

            vm.center = [lat,lng];
        };

        $rootScope.$on('setPinActive', function(event, data) {
            _.forEach(vm.data, function (v, k) {
                _.forEach(data, function (v2, k2) {
                    if(v2.active){
                        if(v.id === v2.id){

                            v.fields.active = v2.fields.active;

                            var icon_temp = '';

                            if(v.fields.active){
                                icon_temp = vm.icon_regular_active;

                                if (v.fields.icon === 'three'){
                                    icon_temp = vm.icon_three_active;
                                }else if (v.fields.icon === 'four') {
                                    icon_temp = vm.icon_four_active;
                                }else if (v.fields.icon === 'flat'){
                                    icon_temp = vm.icon_flat_active;
                                }
                            }else{
                                icon_temp = vm.icon_regular;

                                if (v.fields.icon === 'three'){
                                    icon_temp = vm.icon_three;
                                }else if (v.fields.icon === 'four') {
                                    icon_temp = vm.icon_four;
                                }else if (v.fields.icon === 'flat') {
                                    icon_temp = vm.icon_flat;
                                }
                            }

                            v.icon = icon_temp;
                        }
                    }
                });
            });
        });

        function GetParkingAll() {
            AirtableServices.get_parking_all().then(function (response) {
                vm.data = response.data.records;
                ProcessData();

                if(vm.myLoc && place_marker_loc && !vm.isMobile){

                    var temp_lat = parseFloat(place_marker_loc.lat);
                    var temp_lng = parseFloat(place_marker_loc.lng);
                    var temp_lng_center = vm.isMobile ? temp_lng : temp_lng-0.003;

                    vm.latlng = [temp_lat,temp_lng];
                    vm.center = [temp_lat,temp_lng_center];
                    vm.zoom = 17;

                    vm.from_latlng = {lat:temp_lat, lng:temp_lng};
                    calculateDistance(vm.from_latlng, vm.data_lat_lng);
                }else if(GeneralServices.getTempSearch()){
                    var loc = GeneralServices.getTempSearch().getPlace().geometry.location;

                    var lat = parseFloat(loc.lat());
                    var lng = parseFloat(loc.lng());

                    var lng_center = vm.isMobile ? lng : lng-0.003;

                    vm.latlng = [lat,lng];
                    vm.center = [lat,lng_center];
                    vm.zoom = 17;

                    vm.from_latlng = {lat:lat, lng:lng};
                    calculateDistance(vm.from_latlng, vm.data_lat_lng);
                }else{
                    vm.center = default_center;
                }

            }, function (error) {
                console.log(error)
            });
        }

        function ProcessData(){
            _.forEach(vm.data, function (v, k) {

                var lat = parseFloat(v.fields.lat);
                var lng = parseFloat(v.fields.lng);

                var icon_temp = vm.icon_regular;

                // var zIndex = 500;
                var zIndex = 100;

                if (v.fields.icon === 'flat'){
                    icon_temp = vm.icon_flat;
                    zIndex = 300;

                }else if (v.fields.icon === 'three'){
                    icon_temp = vm.icon_three;
                    // zIndex = 100;
                    zIndex = 500;

                }else if (v.fields.icon === 'four') {
                    icon_temp = vm.icon_four;
                    // zIndex = 200;
                    zIndex = 400;

                }else if (v.fields.icon === '4.50') {
                    icon_temp = vm.icon_four_point_five;
                    // zIndex = 200;
                    zIndex = 350;

                }else if (v.fields.icon === 'epark') {
                    icon_temp = vm.icon_epark;
                    // zIndex = 400;
                    zIndex = 200;
                }

                v.position = [lat, lng];
                v.icon = icon_temp;
                v.zIndex = zIndex;

                vm.data_lat_lng.push({lat:lat, lng:lng, id:v.id});
            });
        }

        function GetParkingById(id) {
            AirtableServices.get_parking_all().then(function (response) {
            // AirtableServices.get_parking_by_id(id).then(function (response) {

                var list = response.data.records;

                var obj = _.filter(list, function(o) { return o.fields.slug === id; });
                var data = obj[0]['fields'];

                // var data = response.data.fields;

                var lat = parseFloat(data.lat);
                var lng = parseFloat(data.lng);

                var icon_temp = vm.icon_regular;

                if (data.icon === 'flat')
                    icon_temp = vm.icon_flat;
                else if (data.icon === 'three')
                    icon_temp = vm.icon_three;
                else if (data.icon === 'four')
                    icon_temp = vm.icon_four;
                else if (data.icon === '4.50')
                    icon_temp = vm.icon_four_point_five;
                else if (data.icon === 'epark')
                    icon_temp = vm.icon_epark;

                var obj = {
                    id: null,
                    position: [lat,lng],
                    icon: icon_temp
                };

                vm.data.push(obj);

                if ($stateParams.lat && $stateParams.lng){

                    bounds.extend({lat:lat,lng:lng});

                    var temp_lat = parseFloat($stateParams.lat);
                    var temp_lng = parseFloat($stateParams.lng);

                    vm.latlng = [temp_lat,temp_lng];
                    bounds.extend({lat:temp_lat,lng:temp_lng});
                    vm.map.fitBounds(bounds);

                    $('html, body').animate({
                        scrollTop:$('html').offset().top
                    },1000);

                }else{
                    vm.center = [data.lat,data.lng];
                    vm.zoom = 18;
                }

            }, function (error) {
                console.log(error)
            });
        }

        vm.redirectMap = function(evt,item){
            if(item.id !== null){

                var active;

                _.forEach(vm.data, function (v, k) {
                    if(v.id === item.id){
                        v.fields.active = !v.fields.active;
                        active = v.fields.active;

                        var icon_temp = '';

                        if(v.fields.active){
                            icon_temp = vm.icon_regular_active;

                            if (v.fields.icon === 'three'){
                                icon_temp = vm.icon_three_active;
                            }else if (v.fields.icon === 'four') {
                                icon_temp = vm.icon_four_active;
                            }else if (v.fields.icon === 'flat'){
                                icon_temp = vm.icon_flat_active;
                            }
                        }else{
                            icon_temp = vm.icon_regular;

                            if (v.fields.icon === 'three'){
                                icon_temp = vm.icon_three;
                            }else if (v.fields.icon === 'four') {
                                icon_temp = vm.icon_four;
                            }else if (v.fields.icon === 'flat') {
                                icon_temp = vm.icon_flat;
                            }
                        }
                        v.icon = icon_temp;
                    }
                });

                $rootScope.$emit('ActivateListItem',{id: item.id,active: active});

                // Analytics.trackEvent('marker', 'click', item.fields.slug);
            }
        };

        function calculateDistance(objFrom, arrTo){
            var temp = [];

            _.forEach(arrTo, function(v,k){
                temp.push({id:v.id, distance:parseFloat(GeneralServices.calculate(objFrom,v))});
            });

            var temp2 = {
                value: temp,
                origin: objFrom
            };

            _.forEach(vm.data, function(v1,k1){
                _.forEach(temp2.value, function(v2,k2){
                    if (v1.id === v2.id){
                        v1.fields.distance = v2.distance + "mi";
                        v1.fields.origin = temp2.origin;
                    }
                });
            });

            GeneralServices.put_object_cookies('get_distance',temp2);
            $scope.$emit('showDistance', temp2);

        }

        var apple_map  = [
            {
                "featureType": "landscape.man_made",
                "elementType": "geometry",
                "stylers": [
                    {
                        "color": "#f7f1df"
                    }
                ]
            },
            {
                "featureType": "landscape.natural",
                "elementType": "geometry",
                "stylers": [
                    {
                        "color": "#d0e3b4"
                    }
                ]
            },
            {
                "featureType": "landscape.natural.terrain",
                "elementType": "geometry",
                "stylers": [
                    {
                        "visibility": "off"
                    }
                ]
            },
            {
                "featureType": "poi",
                "elementType": "labels",
                "stylers": [
                    {
                        "visibility": "on"
                    }
                ]
            },
            {
                "featureType": "poi.business",
                "elementType": "all",
                "stylers": [
                    {
                        "visibility": "off"
                    }
                ]
            },
            {
                "featureType": "poi.medical",
                "elementType": "geometry",
                "stylers": [
                    {
                        "color": "#fbd3da"
                    }
                ]
            },
            {
                "featureType": "poi.park",
                "elementType": "geometry",
                "stylers": [
                    {
                        "color": "#bde6ab"
                    }
                ]
            },
            {
                "featureType": "road",
                "elementType": "geometry.stroke",
                "stylers": [
                    {
                        "visibility": "on"
                    }
                ]
            },
            {
                "featureType": "road",
                "elementType": "labels",
                "stylers": [
                    {
                        "visibility": "on"
                    }
                ]
            },
            {
                "featureType": "road.highway",
                "elementType": "geometry.fill",
                "stylers": [
                    {
                        "color": "#ffe15f"
                    }
                ]
            },
            {
                "featureType": "road.highway",
                "elementType": "geometry.stroke",
                "stylers": [
                    {
                        "color": "#efd151"
                    }
                ]
            },
            {
                "featureType": "road.arterial",
                "elementType": "geometry.fill",
                "stylers": [
                    {
                        "color": "#ffffff"
                    }
                ]
            },
            {
                "featureType": "road.local",
                "elementType": "geometry.fill",
                "stylers": [
                    {
                        "color": "black"
                    }
                ]
            },
            {
                "featureType": "transit.station.airport",
                "elementType": "geometry.fill",
                "stylers": [
                    {
                        "color": "#cfb2db"
                    }
                ]
            },
            {
                "featureType": "water",
                "elementType": "geometry",
                "stylers": [
                    {
                        "color": "#a2daf2"
                    }
                ]
            }
        ];

        var default_map = [
            {
                featureType: "road.highway",
                elementType: "geometry.fill",
                stylers: [
                    { color: "#b2b2b2" }
                ]
            },
            {
                featureType: "road.highway",
                elementType: "geometry.stroke",
                stylers: [
                    { color: "#969696" },
                    { weight: 0.8 }
                ]
            },
            {
                featureType: "road.highway",
                elementType: "labels.text.stroke",
                stylers: [
                    { color: "#b2b2b2" }
                ]
            },
            {
                elementType: "labels.text.fill",
                stylers: [
                    { color: "#2f2f2f" }
                ]
            },
            {
                featureType: "road.arterial",
                elementType: "geometry",
                stylers: [
                    { color: "#c7c7c7" }
                ]
            },
            {
                featureType: "road.arterial",
                elementType: "labels.text.stroke",
                stylers: [
                    { color: "#c7c7c7" }
                ]
            },
            {
                featureType: "water",
                elementType: "geometry.fill",
                stylers: [
                    { color: "#afc9d1" }
                ]
            },
            {
                featureType: "transit.line",
                elementType: "geometry.fill",
                stylers: [
                    { color: "#b2b2b2" }
                ]
            },
            {
                featureType: "transit.line",
                elementType: "labels.text.stroke",
                stylers: [
                    { color: "#b2b2b2" }
                ]
            },
            {
                featureType: "transit",
                elementType: "labels.icon",
                stylers: [
                    { saturation: -100 },
                    { gamma: 0.84 }
                ]
            },
            {
                featureType: "poi",
                elementType: "geometry.fill",
                stylers: [
                    { color: "#b2b2b2" }
                ]
            },
            {
                featureType: "poi",
                elementType: "labels",
                stylers: [
                    { visibility: "off" }
                ]
            },
            {
                featureType: "transit.station.airport",
                elementType: "labels",
                stylers: [
                    { visibility: "off" }
                ]
            },
            {
                featureType: "road",
                elementType: "labels.icon",
                stylers: [
                    { saturation: -100 },
                    { gamma: 0.78 }
                ]
            }
        ];

        vm.getStyle = function(){
            return apple_map;
        };
    }
})();