(function () {
    'use strict';

    angular
        .module('dspApp')
        .controller('widgetEmbeddableHeaderCtrl', widgetEmbeddableHeaderCtrl);

    widgetEmbeddableHeaderCtrl.$inject = ['$window','$state'];

    function widgetEmbeddableHeaderCtrl($window,$state) {
        var vm = this;

        vm.isMobile = $window.innerWidth < 992;

        function getRndInteger(min, max) {
            return Math.floor(Math.random() * (max - min + 1) ) + min;
        }

        // vm.logo_array = ['img/octopus.png','img/tower.png'];
        vm.logo_array = ['img/octopus.png','img/smith-tower.svg'];

        if(vm.isMobile){
            vm.logo_url = vm.logo_array[1];
            vm.randomNumber = 1;
        }else{
            vm.randomNumber = getRndInteger(1,2)-1;
            vm.logo_url = vm.logo_array[vm.randomNumber];
        }

        vm.goHome = function(){
            $state.go('embeddable-map', {}, {reload: true});
        };
        vm.goExplore = function(){
            $window.open('https://downtownseattle.org/parking/explore', '_blank');
        };

    }
})();
